import { add as dfnAdd, format as dfnFormat } from 'date-fns';
export function format(
  date: Date | number,
  format: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }
): string {
  return dfnFormat(date, format, options);
}

export function add(date: Date | number, duration: Duration) {
  return dfnAdd(date, duration);
}
