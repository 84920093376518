/* eslint-disable @typescript-eslint/no-explicit-any */
import { TransformationType } from '../interfaces';
import { ClassConstructor } from '../interfaces/class-constructor.interface';
import { ClassTransformOptions } from '../interfaces/class-transform-options.interface';
import { PROP_METADATA } from '../util';

export abstract class BaseExecutor {
  abstract type: TransformationType;
  canExecute(type: TransformationType): boolean {
    return this.type === type;
  }
  abstract execute<T extends Record<string, any>>(
    source: any,
    value: any,
    targetType: ClassConstructor<T>,
    options?: ClassTransformOptions
  ): T;
  propMetadata(typeOrFunc: ClassConstructor<any>): { [key: string]: any[] } {
    const propMetadata: { [key: string]: any[] } = {};
    const ownPropMetadata = this._ownPropMetadata(typeOrFunc);
    if (ownPropMetadata) {
      Object.keys(ownPropMetadata).forEach((propName) => {
        const decorators: any[] = [];
        if (propName in propMetadata) {
          decorators.push(...propMetadata[propName]);
        }
        decorators.push(...ownPropMetadata[propName]);
        propMetadata[propName] = decorators;
      });
    }
    return propMetadata;
  }
  protected _ownPropMetadata(
    typeOrFunc: ClassConstructor<any>
  ): { [key: string]: any[] } | null {
    if (PROP_METADATA in typeOrFunc) {
      return typeOrFunc[PROP_METADATA] as { [key: string]: any[] };
    }
    return null;
  }
}
