import { UserAccountModel } from '@shared/data-access/models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as UserActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface UserState extends EntityState<UserAccountModel> {
  selectedId?: string | number; // which User record has been selected
  loaded: boolean; // has the User list been loaded
  error?: string | null; // last known error (if any)
}

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: UserState;
}

export const userAdapter: EntityAdapter<UserAccountModel> =
  createEntityAdapter<UserAccountModel>();

export const initialUserState: UserState = userAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const reducer = createReducer(
  initialUserState,
  on(UserActions.initUser, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(UserActions.loadUserSuccess, (state, { user }) =>
    {
      return userAdapter.setAll([user], { ...state,selectedId:user.id, loaded: true })
      // return {...state,selectedId:user.id}
    }
  ),
  on(UserActions.loadUserFailure, (state, { error }) => ({ ...state, error }))
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
