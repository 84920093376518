<div class="redoc-vertical-navigation-wrapper">
  <!-- Header -->
  <div class="redoc-vertical-navigation-header">
    <ng-content select="[redocVerticalNavigationHeader]"></ng-content>
  </div>

  <!-- Content -->
  <div
    class="redoc-vertical-navigation-content"
    [redocScrollbar]="true"
    [redocScrollbarOptions]="{ wheelPropagation: inner, suppressScrollX: true }"
    #navigationContent
  >
    <!-- Content header -->
    <div class="redoc-vertical-navigation-content-header">
      <ng-content select="[cimbVerticalNavigationContentHeader]"></ng-content>
    </div>

    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Aside -->
        <ng-container *ngIf="item.type === 'aside'">
          <redoc-vertical-navigation-aside-item
            [item]="item"
            [name]="name"
            [activeItemId]="activeAsideItemId"
            [autoCollapse]="autoCollapse"
            [skipChildren]="true"
            (click)="toggleAside(item)"
          ></redoc-vertical-navigation-aside-item>
        </ng-container>

        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic'">
          <redoc-vertical-navigation-basic-item
            [item]="item"
            [name]="name"
          ></redoc-vertical-navigation-basic-item>
        </ng-container>

        <!-- Collapsable -->
        <ng-container *ngIf="item.type === 'collapsable'">
          <redoc-vertical-navigation-collapsable-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
          ></redoc-vertical-navigation-collapsable-item>
        </ng-container>

        <!-- Divider -->
        <ng-container *ngIf="item.type === 'divider'">
          <redoc-vertical-navigation-divider-item
            [item]="item"
            [name]="name"
          ></redoc-vertical-navigation-divider-item>
        </ng-container>

        <!-- Group -->
        <ng-container *ngIf="item.type === 'group'">
          <redoc-vertical-navigation-group-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
          ></redoc-vertical-navigation-group-item>
        </ng-container>

        <!-- Spacer -->
        <ng-container *ngIf="item.type === 'spacer'">
          <redoc-vertical-navigation-spacer-item
            [item]="item"
            [name]="name"
          ></redoc-vertical-navigation-spacer-item>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Content footer -->
    <div class="redoc-vertical-navigation-content-footer">
      <ng-content select="[redocVerticalNavigationContentFooter]"></ng-content>
    </div>
  </div>

  <!-- Footer -->
  <div class="redoc-vertical-navigation-footer">
    <ng-content select="[redocVerticalNavigationFooter]"></ng-content>
  </div>
</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
  <div
    class="redoc-vertical-navigation-aside-wrapper"
    [redocScrollbar]="true"
    [redocScrollbarOptions]="{ wheelPropagation: inner, suppressScrollX: true }"
    [@fadeInLeft]="position === 'left'"
    [@fadeInRight]="position === 'right'"
    [@fadeOutLeft]="position === 'left'"
    [@fadeOutRight]="position === 'right'"
  >
    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Aside -->
        <ng-container
          *ngIf="item.type === 'aside' && item.id === activeAsideItemId"
        >
          <redoc-vertical-navigation-aside-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
          ></redoc-vertical-navigation-aside-item>
        </ng-container>
        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic'">
          <redoc-vertical-navigation-basic-item
            [item]="item"
            [name]="name"
          ></redoc-vertical-navigation-basic-item>
        </ng-container>
        <!-- Collapsable -->
        <ng-container *ngIf="item.type === 'collapsable'">
          <redoc-vertical-navigation-collapsable-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
          ></redoc-vertical-navigation-collapsable-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
