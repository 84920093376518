import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { VerticalNavigationAsideItemComponent } from "./components/aside/aside.component";
import { VerticalNavigationBasicItemComponent } from "./components/basic/basic.component";
import { VerticalNavigationCollapsableItemComponent } from "./components/collapsable/collapsable.component";
import { VerticalNavigationDividerItemComponent } from "./components/divider/divider.component";
import { VerticalNavigationGroupItemComponent } from "./components/group/group.component";
import { VerticalNavigationSpacerItemComponent } from "./components/spacer/spacer.component";
import { VerticalNavigationComponent } from "./vertical-navigation.component";
import { RedocScrollbarDirective } from "../../scrollbar/scrollbar.directive";

@NgModule({
  declarations: [
    VerticalNavigationComponent,
    VerticalNavigationAsideItemComponent,
    VerticalNavigationBasicItemComponent,
    VerticalNavigationCollapsableItemComponent,
    VerticalNavigationDividerItemComponent,
    VerticalNavigationGroupItemComponent,
    VerticalNavigationSpacerItemComponent,
  ],
  imports: [CommonModule, MatTooltipModule, MatIconModule, RouterModule, RedocScrollbarDirective],
  exports: [VerticalNavigationComponent],
})
export class RedocVerticalNavigationModule {}
