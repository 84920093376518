import { Injectable, inject } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';

import * as UserActions from './user.actions';
import * as UserFeature from './user.reducer';
import * as UserSelectors from './user.selectors';
import { UserAdapterBase } from '@shared/features/auth';
import { UserAccountModel } from '@shared/data-access/models';
import { Observable, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserFacade implements UserAdapterBase<UserAccountModel> {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(UserSelectors.selectUserLoaded));
  allUser$ = this.store.pipe(select(UserSelectors.selectAllUser));
  set user(value: UserAccountModel) {
    this.store.dispatch(UserActions.loadUserSuccess({ user: value }));
  }
  get user$(): Observable<UserAccountModel> {
    return this.store
      .pipe(select(UserSelectors.selectEntity))
      .pipe(filter((user) => !!user)) as Observable<UserAccountModel>;
  }
  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(UserActions.initUser());
  }
}
