/* eslint-disable @typescript-eslint/no-namespace */
import { Injectable, inject } from '@angular/core';
import { REQUEST } from '@shared/core/express';
import { Request } from 'express';
import { RedocAuthStorage, RedocAuthStorageType } from './storage.interface';
const REASSIGN = Symbol('reassign');
function attachSessionObject(req: Request, sessionName: string, value: any) {
  // console.log('attachSessionObject', value);
  Object.defineProperty(req, sessionName, {
    enumerable: true,
    get() {
      return value;
    },
    set(arg) {
      if (arg === null || arg === undefined || arg[REASSIGN]) {
        value = arg;
      } else {
        throw new TypeError('session object cannot be reassigned');
      }
      return undefined;
    },
  });
}
export type AuthTransferStateOptions = Record<string, any> & {
  access_token?: string;
  refresh_token?: string;
};
@Injectable()
export class AuthTransferStateStorage implements RedocAuthStorage {
  type: RedocAuthStorageType = 'transferState';
  sessionName = 'redoc';
  private request = inject(REQUEST);
  setItem(key: string, value: string): void {
    const state: AuthTransferStateOptions = this.request.redoc || {};
    state[key] = value;
    attachSessionObject(this.request, this.sessionName, state);
  }
  getItem(key: string): string | null {
    const state: AuthTransferStateOptions = this.request.redoc || {};
    return state[key] || null;
  }
  removeItem(key: string): void {
    const state: AuthTransferStateOptions = this.request.redoc || {};
    delete state[key];
    attachSessionObject(this.request, this.sessionName, state);
  }
  clear(): void {
    attachSessionObject(this.request, this.sessionName, {});
  }
}

declare global {
  namespace Express {
    interface Request {
      redoc?: AuthTransferStateOptions;
      transferState?: Record<string, any>;
    }
  }
}
