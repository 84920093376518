import { BaseModel, Default, Field, Type } from '@redocco/core';
import {
  IBusinessUnit,
  IBusinessUnitMetaData,
} from '../interfaces/business-unit.interface';
import { PaymentInfoModel } from './payment-info.model';

export class BusinessUnitMetaData
  extends BaseModel
  implements Required<IBusinessUnitMetaData>
{
  @Field()
  countryCode!: string;
  @Field()
  phoneNumber!: string;
  @Field()
  licenseNumber!: string;
  @Field({ optional: true })
  @Type(PaymentInfoModel)
  paymentInfo!: PaymentInfoModel;
  @Field({ optional: true })
  postalCode!: string;
  @Field({ optional: true })
  unitNumber!: string;
  @Field({ optional: true })
  blockNumber!: string;
  @Field({ optional: true })
  streetName!: string;
  @Field({ optional: true })
  buildingName!: string;
  @Field({ optional: true })
  floorNumber!: string;
  @Field({ optional: true })
  gstRegNo?: string;
}

export class BusinessUnitModel extends BaseModel implements IBusinessUnit {
  @Field()
  id!: number;
  @Field()
  name!: string;
  @Field()
  status!: string;
  @Field()
  logo!: string;
  @Field()
  addressLine1!: string;
  @Field()
  addressLine2!: string;
  @Field()
  city!: string;
  @Field()
  country!: string;
  @Field()
  state!: string;
  @Field()
  zipCode!: string;
  @Field()
  language!: string;
  @Field()
  currencyCode!: string;
  @Field()
  @Default(false)
  isActive!: boolean;
  @Field()
  timeZone!: string;
  @Field()
  createdBy!: number;
  @Field()
  organizationId!: number;
  @Field()
  @Type(BusinessUnitMetaData)
  metadata!: BusinessUnitMetaData;

  // @Field()
  // settings!: Array<SettingModel>;
}
