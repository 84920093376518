import { LogoHeaderDirective } from './../../../common/logo-header/logo-header.directive';
import { VerticalGroupActionHeaderDirective } from './../group-action/vertical-group-action-header.directive';
import {
  VERTICAL_NAVIGATION_DATA_LOADER,
  NavigationDataLoaderBaseService,
} from '../vertical-navigation-data-loader.service';
import {
  Component,
  ContentChild,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  VerticalNavigationComponent,
  RedocVerticalNavigationModule,
} from '../../../common/navigation/vertical-navigation';
import { MatIconModule } from '@angular/material/icon';
import { Subject, takeUntil } from 'rxjs';
import {
  VerticalNavigationAppearance,
  NavigationItem,
  VerticalNavigationGroup,
} from '../../../common/navigation/navigation.types';
import { RedocNavigationService } from '../../../common/navigation/navigation.service';
import { RedocMediaWatcherService } from '@shared/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'redoc-classic-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RedocVerticalNavigationModule,
    MatButtonModule,
    MatIconModule,
    LogoHeaderDirective,
    VerticalGroupActionHeaderDirective,
    PortalModule,
  ],
  templateUrl: './classic-layout.component.html',
  styleUrls: ['./classic-layout.component.scss'],
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall!: boolean;
  naviagtionAppearance: VerticalNavigationAppearance = 'default';
  naviagtionMode: 'over' | 'side' = 'side';
  navigation!: VerticalNavigationGroup;
  navigationSelected: NavigationItem[] = [];
  /** Content for the tab label given by `<ng-template redocGroupActionHeader>`. */
  @ContentChild(VerticalGroupActionHeaderDirective)
  get templateLabel(): VerticalGroupActionHeaderDirective | undefined {
    return this._templateLabel;
  }
  set templateLabel(value: VerticalGroupActionHeaderDirective | undefined) {
    this._templateLabel = value;
  }

  private _templateLabel?: VerticalGroupActionHeaderDirective;
  /** Content for the tab label given by `<ng-template redocLogoHeader>`. */
  @ContentChild(LogoHeaderDirective)
  get logoLabel(): LogoHeaderDirective | undefined {
    return this._logoLabel;
  }
  set logoLabel(value: LogoHeaderDirective | undefined) {
    this._logoLabel = value;
  }

  private _logoLabel?: LogoHeaderDirective;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  isNavigationOpened = false;
  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    @Inject(VERTICAL_NAVIGATION_DATA_LOADER)
    private _navigationService: NavigationDataLoaderBaseService,
    private _redocNavigationService: RedocNavigationService,
    private _mediaWatcherService: RedocMediaWatcherService
  ) {}
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: VerticalNavigationGroup) => {
        console.log('hello _navigationService', navigation);
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._mediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases, matchingQueries }) => {
        // Check if the screen is small
        const isMobileScreen = matchingAliases.length === 0;
        if (isMobileScreen) {
          this.naviagtionMode = 'over';
          this.naviagtionAppearance = 'default';
        } else {
          this.naviagtionMode = 'side';
          this.isScreenSmall =
            matchingAliases.length === 0 || !matchingAliases.includes('lg');
          this.naviagtionAppearance = this.isScreenSmall
            ? 'compact'
            : 'default';
        }

        this._redocNavigationService
          .getComponent<VerticalNavigationComponent>('mainNavigation')
          ?.closeAside();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._redocNavigationService.getComponent<VerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
