/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import {
  ActivateTokenDto,
  ForgotPasswordDto,
  LoginDto,
  LoginResponseDto,
  RefreshTokenResponseDto,
} from '@shared/data-access/dtos';
import { AuthApiLoader } from '@shared/features/auth';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuthApiLoader<
  LoginDto,
  LoginResponseDto,
  RefreshTokenResponseDto
> {
  private _authApiService = inject(AuthApiService);

  /**
   * Sign in
   *
   * @param credentials
   */
  override signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    return this._authApiService.signIn(credentials);
  }

  /**
   * Refresh token
   *
   * @param credentials
   */
  override refreshToken(
    refreshToken: string
  ): Observable<RefreshTokenResponseDto> {
    return this._authApiService.refreshToken(refreshToken);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: { password: string }): Observable<any> {
    return this._authApiService.resetPassword(data);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  override forgotPassword(data: ForgotPasswordDto): Observable<any> {
    return this._authApiService.forgotPassword(data);
  }

   /**
   * Verify activate token
   *
   * @param token
   */
   verifyActivateToken(data:ActivateTokenDto): Observable<any> {
    return this._authApiService.verifyActivateToken(data);
  }

  getMe(): Observable<any> {
    return this._authApiService.getMe();
  }
}
