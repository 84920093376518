import { Directive, OnDestroy, OnInit, inject } from '@angular/core';
import { Layout, LayoutConfig } from './layout.types';
import { Subject, combineLatest, filter, map, takeUntil } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LayoutService } from './layout.service';
import { DOCUMENT } from '@angular/common';
import {
  ENABLED_DARK_MODE,
  RedocMediaWatcherService,
} from '@shared/cdk/layout';

@Directive()
export abstract class LayoutBase implements OnInit, OnDestroy {
  layout!: Layout;
  config!: LayoutConfig;
  scheme!: 'dark' | 'light';
  theme!: string;
  private isEnabledDarkMode = inject(ENABLED_DARK_MODE);
  private configService = inject(LayoutService);
  private mediaWatcherService = inject(RedocMediaWatcherService);
  private _document = inject(DOCUMENT);
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  constructor(
    protected _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit(): void {
    // Set the theme and scheme based on the configuration
    combineLatest([
      this.configService.config$,
      this.mediaWatcherService.onMediaQueryChange$([
        '(prefers-color-scheme: dark)',
        '(prefers-color-scheme: light)',
      ]),
    ])
      .pipe(
        map(([config, mediaWatcher]) => {
          const options = {
            scheme: config.scheme,
            theme: config.theme,
          };

          // If the scheme is set to 'auto'...
          if (config.scheme === 'auto') {
            // Decide the scheme using the media query
            options.scheme = mediaWatcher.breakpoints[
              '(prefers-color-scheme: dark)'
            ]
              ? 'dark'
              : 'light';
          }

          return options;
        }),

        takeUntil(this._unsubscribeAll)
      )
      .subscribe((options) => {
        // console.log('options -->', options, this._document.body.classList);

        // Store the options
        this.scheme = options.scheme;
        this.theme = options.theme;

        // Update the scheme and theme
        this._updateScheme();
        this._updateTheme();
      });
    // Subscribe to config changes
    this.configService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: LayoutConfig) => {
        // Store the config
        this.config = config;

        // Update the layout
        this._updateLayout();
      });
    // Subscribe to NavigationEnd event
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        // Update the layout
        this._updateLayout();
      });
  }

  /**
   * Update the selected layout
   */
  private _updateLayout(): void {
    // Get the current activated route
    let route = this._activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // 1. Set the layout from the config
    this.layout = this.config.layout;

    // 2. Get the query parameter from the current route and
    // set the layout and save the layout to the config
    const layoutFromQueryParam = route.snapshot.queryParamMap.get(
      'layout'
    ) as Layout;
    if (layoutFromQueryParam) {
      this.layout = layoutFromQueryParam;
      if (this.config) {
        this.config.layout = layoutFromQueryParam;
      }
    }
    // 3. Iterate through the paths and change the layout as we find
    // a config for it.
    //
    // The reason we do this is that there might be empty grouping
    // paths or componentless routes along the path. Because of that,
    // we cannot just assume that the layout configuration will be
    // in the last path's config or in the first path's config.
    //
    // So, we get all the paths that matched starting from root all
    // the way to the current activated route, walk through them one
    // by one and change the layout as we find the layout config. This
    // way, layout configuration can live anywhere within the path and
    // we won't miss it.
    //
    // Also, this will allow overriding the layout in any time so we
    // can have different layouts for different routes.
    const paths = route.pathFromRoot;
    paths.forEach((path) => {
      // Check if there is a 'layout' data
      if (
        path.routeConfig &&
        path.routeConfig.data &&
        path.routeConfig.data['layout']
      ) {
        // Set the layout
        this.layout = path.routeConfig.data['layout'];
      }
    });
  }
  /**
   * Update the selected scheme
   *
   * @private
   */
  private _updateScheme(): void {
    // Check dark mode is enabled
    if (this.scheme === 'dark' && !this.isEnabledDarkMode) {
      return;
    }
    // Remove class names for all schemes
    this._document.body.classList?.remove('light', 'dark');

    // Add class name for the currently selected scheme
    this._document.body.classList?.add(this.scheme);
  }
  /**
   * Update the selected theme
   *
   * @private
   */
  private _updateTheme(): void {
    // Check classList has forEach function, bug ssr
    // Find the class name for the previously selected theme and remove it
    if (this._document.body.classList.forEach) {
      this._document.body.classList.forEach((className: string) => {
        if (className.startsWith('theme-')) {
          this._document.body.classList.remove(
            className,
            className.split('-')[1]
          );
        }
      });
    }

    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-${this.theme}`);
  }
}
