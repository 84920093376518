import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CopyrightComponent, LogoComponent } from '@redocco/material-ui';

@Component({
  selector: 'redoc-wellcome-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, LogoComponent, CopyrightComponent],
  templateUrl: './wellcome-layout.component.html',
  styleUrl: './wellcome-layout.component.scss',
})
export class WellcomeLayoutComponent {}
