import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import {
  AuthLoginResponseDto,
  RefreshTokenResponseDto,
  AuthLoginDto,
  IUser,
  AuthForgotPasswordDto,
} from './auth.model';

@Injectable()
export abstract class AuthApiLoader<
  LoginDto extends AuthLoginDto,
  LoginResponseDto extends AuthLoginResponseDto,
  RefreshResponseDto extends RefreshTokenResponseDto
> {
  private authenticated = false;
  /**
   * Sign in
   *
   * @param credentials
   */
  abstract signIn(credentials: LoginDto): Observable<LoginResponseDto>;
  /**
   * Refresh token
   *
   * @param credentials
   */
  abstract refreshToken(refreshToken: string): Observable<RefreshResponseDto>;
  /**
   * Refresh token
   *
   * @param credentials
   */
  abstract forgotPassword(data: AuthForgotPasswordDto): Observable<unknown>;
}

export class AuthApiLoaderBase extends AuthApiLoader<
  AuthLoginDto,
  AuthLoginResponseDto,
  RefreshTokenResponseDto
> {
  override signIn(credentials: AuthLoginDto): Observable<AuthLoginResponseDto> {
    return of({
      accessToken: '',
      refreshToken: '',
    });
  }
  override refreshToken(
    refreshToken: string
  ): Observable<AuthLoginResponseDto> {
    return of({
      accessToken: '',
      refreshToken: '',
    });
  }
  override forgotPassword(data: AuthForgotPasswordDto): Observable<unknown> {
    throw new Error('Method not implemented.');
  }
}

// User

// @Injectable()
export interface UserAdapterBase<T> {
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: T);

  get user$(): Observable<T>;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
}
@Injectable({
  providedIn: 'root',
})
export class UserAdapter implements UserAdapterBase<IUser> {
  private _user: ReplaySubject<IUser> = new ReplaySubject<IUser>();
  set user(value: IUser) {
    // Store the value
    this._user.next(value);
  }
  get user$(): Observable<IUser> {
    return this._user.asObservable();
  }
}

// set user(value: T) {
//   // Store the value
//   this._user.next(value);
// }

// get user$(): Observable<T> {
//   return this._user.asObservable();
// }

// // -----------------------------------------------------------------------------------------------------
// // @ Public methods
// // -----------------------------------------------------------------------------------------------------

// get(): Observable<T> {
//   return of();
// }
