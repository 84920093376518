/* eslint-disable @typescript-eslint/no-explicit-any */
import { Transform } from './transform.decorator';

/**
 * Set up default value for property. By default, the defaultValue will be applied if original value is undefined.
 * To decide when to use default values, let input conditionFn
 * @param defaultValue
 * @param conditionFn Optional
 * @returns
 */
export function Default(
  defaultValue: unknown,
  conditionFn?: (value: any) => boolean
) {
  return Transform({
    transform: ({ value }) => {
      if (conditionFn) {
        if (conditionFn(value)) {
          return defaultValue;
        }
      } else {
        if (typeof value === 'undefined') {
          return defaultValue;
        }
      }

      return value;
    },
  });
}
