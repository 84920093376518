import { inject } from "@angular/core";
import { ImageLoaderFn, MediaStorageService } from "@redocco/material-ui";

// export const imageLoaderFn:ImageLoaderFn = (urlPath:string)=> {
//   console.log('urlPath',urlPath)
//   const mediaStorage = inject(MediaStorageService);
//   return mediaStorage.getPublicLink({ keyName:urlPath})
// };
export function imageLoaderFn() :ImageLoaderFn{
  const mediaStorage = inject(MediaStorageService);
  return (urlPath:string)=>{
    return mediaStorage.getPublicLink({ keyName:urlPath})
  }
}

