import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { DateConverter, IsBrowser } from '@redocco/core';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthApiLoader } from './auth-api.loader';
import { AUTH_API_LOADER, AUTH_STORAGE } from './auth-feature';
import { AuthLoginResponseDto, RefreshTokenResponseDto } from './auth.model';
import { AuthUtils } from './auth.utils';
import { RedocAuthCookieStorage, RedocAuthStorageOptions } from './storages';
import { AuthActionEvent } from './auth.service';
import { isPlatformServer } from '@angular/common';
import { ForgotPasswordDto } from '@shared/data-access/dtos';

@Injectable()
export class AuthSSRProvider {
  actionEvent = new ReplaySubject<AuthActionEvent>(1);
  // cookieStorage
  cookieStorage = inject(RedocAuthCookieStorage);
  // localStorage
  storage = inject(AUTH_STORAGE);
  // storage = inject(AUTH_STORAGE);
  private authenticated = false;
  platformId = inject(PLATFORM_ID);
  isServer = isPlatformServer(this.platformId);
  /**
   * Constructor
   */
  constructor(
    @Inject(AUTH_API_LOADER) private apiService: AuthApiLoader<any, any, any>
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    const options: RedocAuthStorageOptions = {
      expires: DateConverter.add(new Date(), { days: 2 }),
    };
    this.storage.setItem('access_token', token, options);
  }

  get accessToken(): string {
    return this.storage.getItem('access_token') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set refreshToken(token: string) {
    const options: RedocAuthStorageOptions = {
      expires: DateConverter.add(new Date(), { days: 2 }),
    };
    this.storage.setItem('refresh_token', token, options);
  }

  get refreshToken(): string {
    return this.storage.getItem('refresh_token') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *  This function only run on Client
   * @param credentials
   */
  signIn<TLoginDto, R extends AuthLoginResponseDto>(
    credentials: TLoginDto
  ): Observable<R> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this.apiService.signIn(credentials).pipe(
      switchMap((response: R) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Push notification
        this.actionEvent.next(AuthActionEvent.LOGIN);

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   * This function only run on Client
   */
  signInUsingRefreshToken(): Observable<boolean> {
    console.log('signInUsingRefreshToken');
    // this.accessToken = '';
    if (!this.refreshToken || AuthUtils.isTokenExpired(this.refreshToken)) {
      return of(false);
    }
    // Renew token
    return this.apiService.refreshToken(this.refreshToken).pipe(
      switchMap((response: RefreshTokenResponseDto) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Return true
        return of(true);
      }),
      catchError(() =>
        // Return false
        of(false)
      )
    );
  }
  /**
   * Sign out
   * This function only run on Client
   */

  signOut(): void {
    // Remove the tokens from the local storage
    this.storage.removeItem('access_token');
    this.storage.removeItem('refresh_token');

    // Set the authenticated flag to false
    this.authenticated = false;

    // Push notification
    this.actionEvent.next(AuthActionEvent.LOGOUT);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // if (this.isServer) {
    //   return of(true);
    // }
    // Check if the user is logged in
    if (this.authenticated) {
      return of(true);
    }
    const c_user = this.cookieStorage.getItem('redoc.c_user');
    console.log('check c_user', c_user);
    if (!c_user) {
      return of(false);
    }
    return of(true);
    //   const accessToken = this.accessToken;
    //   // Check the access token availability
    //   console.log('check accessToken', accessToken);
    //   if (!accessToken) {
    //     return of(false);
    //   }

    //   try {
    //     const { userId } = AuthUtils.decodeToken(accessToken);
    //     if (String(userId) !== this.cookieStorage.getItem('redoc.c_user')) {
    //       return of(false);
    //     }
    //     console.log('check compare accessToken with c_user', accessToken);
    //     if (AuthUtils.isTokenExpired(accessToken)) {
    //       console.log('check accessToken expire', accessToken);
    //       return this.signInUsingRefreshToken();
    //     }
    //   } catch (error) {
    //     console.log('error', error);
    //     this.signOut();
    //     return of(false);
    //   }
    //   return of(true);
  }
  forgotPassword(data: ForgotPasswordDto): Observable<unknown> {
    return this.apiService.forgotPassword(data);
  }
}
