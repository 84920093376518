import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, throwError } from 'rxjs';
import { IMediaStorageConfig } from './media-storage.constant';
import { FilePayloadRequest, GET_PUBLIC_LINK_FN, GET_UPLOAD_LINK_FN, PublicLinkPayload, REDOC_MEDIA_STORAGE, UploadLinkPayload } from './token';

@Injectable()
export class MediaStorageService {
  static STORAGE_UPLOAD_LINK = 'storage/upload/:type';
  static STORAGE_PUBLIC_LINK = 'storage/link';
  protected httpClient: HttpClient = inject(HttpClient)
  protected config: IMediaStorageConfig = inject(REDOC_MEDIA_STORAGE)
  protected getUploadLinkFn = inject(GET_UPLOAD_LINK_FN)
  protected getPublicLinkFn = inject(GET_PUBLIC_LINK_FN)

  getUploadLink<D>(data: UploadLinkPayload): Observable<D> {
    return this.getUploadLinkFn(data);
  }

  getPublicLink<T extends PublicLinkPayload,R>(data: T):Observable<R> {
    return this.getPublicLinkFn<R>(data);
  }

  uploadFile<T extends FilePayloadRequest>(data: T): Observable<{ keyName: string }> {
    if (this.config.storageDrive === 'azure') {
      return this.uploadAzureFile(data).pipe(map(() => ({ keyName: data.fields['key'] })));
    } else if (this.config.storageDrive === 's3') {
      return this.uploadS3File(data).pipe(map(() => ({ keyName: data.fields['key'] })));
    }
    return throwError(() => new Error(`storageDrive ${this.config.storageDrive} is not supported`));
  }

  uploadS3File<T extends FilePayloadRequest>(data: T) {
    const input = new FormData();
    const fields = new Map(Object.entries(data.fields || {}));
    if (data.isPublic) {
      fields.set('acl', 'public-read');
    }

    for (const [key, value] of fields) {
      input.append(key, value);
    }

    input.append('file', data.file);
    return this.httpClient.post(data.url, input);
  }

  uploadAzureFile<T extends FilePayloadRequest>(data: T) {
    const fields = new Map(Object.entries(data.fields || {}));
    const header = new HttpHeaders({
      'x-ms-blob-type': fields.get('x-ms-blob-type'),
      'Content-Type': fields.get('Content-Type') ?? data.file.type,
    });
    console.log('uploadAzureFile -->', data.url, data.file);
    return this.httpClient.put(data.url, data.file, { headers: header });
  }
}
