/* eslint-disable @typescript-eslint/no-explicit-any */
import { assign, get } from 'lodash-es';
import { TransformationType } from '../interfaces';
import { ClassConstructor } from '../interfaces/class-constructor.interface';
import { BaseExecutor } from './base.executor';
import { ClassTransformOptions } from '../interfaces/class-transform-options.interface';

export class PlainToClassExecutor extends BaseExecutor {
  override type = TransformationType.PLAIN_TO_CLASS;
  override execute<T extends Record<string, any>>(
    source: any,
    dist: any,
    targetType: ClassConstructor<T>,
    options?: ClassTransformOptions
  ): T {
    // if(targetType)
    const newValue = new targetType();
    // const constructor = Object.prototype.constructor;
    const propMetadata = this.propMetadata(targetType);
    const inputsFromType: Record<
      string,
      {
        bindingPropertyName: string;
        classPropertyName: string;
        optional: boolean;
        transformFunction?: (value: any) => any;
      }
    > = {};

    const output: Record<string, any> = {};
    for (const field in propMetadata) {
      for (const ann of propMetadata[field]) {
        if (isField(ann)) {
          inputsFromType[field] = {
            bindingPropertyName: ann.alias || field,
            classPropertyName: field,
            optional: ann.optional || false,
          };
          break;
        }
      }
    }
    for (const field in inputsFromType) {
      const selected = inputsFromType[field];
      const valueInput = get(dist, selected.bindingPropertyName);
      let valueOutput = valueInput;
      propMetadata[field].forEach((ann) => {
        if (isTransform(ann)) {
          // valueInput = get(dist, ann.alias || field);
          // const optional = selected.optional;
          // if (!optional && valueInput === undefined) {
          //   throw new Error(
          //     `field ${field} is required, but actual in ${
          //       ann.alias || field
          //     } is ${valueInput}`
          //   );
          // }
          valueOutput =
            typeof ann.transform === 'function'
              ? ann.transform({ obj: dist, value: valueOutput,transformType:this.type })
              : valueOutput;
          
          // inputsFromType[field] = {
          //   bindingPropertyName: ann.alias || field,
          //   classPropertyName: field,
          //   required: ann.required || false,
          //   transformFunction: ann.transform ? ann.transform : null,
          // };
        }
      });
      const optional = !!options?.optional || selected.optional;
      if (!optional && valueOutput === undefined) {
        throw new Error(
          `field ${field} is required, but actual in ${selected.bindingPropertyName} is ${valueOutput}`
        );
      }

      output[field] = valueOutput;
    }
    return assign(newValue, output);
  }
}
function isTransform(value: { ngMetadataName: string }) {
  return value.ngMetadataName === 'Transform';
}
function isField(value: { ngMetadataName: string }) {
  return value.ngMetadataName === 'Field';
}
