import { IsActiveMatchOptions } from "@angular/router";

/**
* Get the equivalent "IsActiveMatchOptions" options for "exact = false".
*/
export const subsetMatchOptionsDefault: IsActiveMatchOptions = {
   paths: 'subset',
   fragment: 'ignored',
   matrixParams: 'ignored',
   queryParams: 'subset',
} as const

/**
 * Get the equivalent "IsActiveMatchOptions" options for "exact = true".
 */
export const exactMatchOptionsDefault: IsActiveMatchOptions ={

    paths: 'exact',
    fragment: 'ignored',
    matrixParams: 'ignored',
    queryParams: 'exact',

}
