import { Inject, Injectable, inject } from '@angular/core';
import { DateConverter } from '@redocco/core';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthApiLoader } from './auth-api.loader';
import { AUTH_API_LOADER, AUTH_SSO_ENABLE, AUTH_STORAGE } from './auth-feature';
import { AuthLoginResponseDto, RefreshTokenResponseDto } from './auth.model';
import { AuthUtils } from './auth.utils';
import { RedocAuthCookieStorage, RedocAuthStorageOptions } from './storages';
import { ForgotPasswordDto } from '@shared/data-access/dtos';
export enum AuthActionEvent {
  LOGIN = 1,
  LOGOUT = 2,
}
export interface AuthProviderBase {
  get accessToken(): string;
  set accessToken(token: string);
  get refreshToken(): string;
  set refreshToken(token: string);
  /**
   * Sign in
   *
   * @param credentials
   */
  signIn<TLoginDto, R extends AuthLoginResponseDto>(
    credentials: TLoginDto
  ): Observable<R>;
  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(): Observable<boolean>;
  /**
   * Sign out
   */
  signOut(): void;
  /**
   * Check the authentication status
   */
  check(): Observable<boolean>;

  forgotPassword(data: ForgotPasswordDto): Observable<unknown>;
}

@Injectable()
export class AuthProvider implements AuthProviderBase {
  actionEvent = new ReplaySubject<AuthActionEvent>(1);
  enableSingleSignOn = inject(AUTH_SSO_ENABLE);
  storage = inject(AUTH_STORAGE);
  cookieStorage = inject(RedocAuthCookieStorage, { optional: true });
  private authenticated = false;

  /**
   * Constructor
   */
  constructor(
    @Inject(AUTH_API_LOADER) private apiService: AuthApiLoader<any, any, any>
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    const options: RedocAuthStorageOptions = {
      expires: DateConverter.add(new Date(), { days: 2 }),
    };
    this.storage.setItem('redoc_access_token', token, options);
  }

  get accessToken(): string {
    return this.storage.getItem('redoc_access_token') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set refreshToken(token: string) {
    const options: RedocAuthStorageOptions = {
      expires: DateConverter.add(new Date(), { days: 2 }),
    };
    this.storage.setItem('redoc_refresh_token', token, options);
  }

  get refreshToken(): string {
    return this.storage.getItem('redoc_refresh_token') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn<TLoginDto, R extends AuthLoginResponseDto>(
    credentials: TLoginDto
  ): Observable<R> {
    // Throw error, if the user is already logged in
    if (this.authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this.apiService.signIn(credentials).pipe(
      switchMap((response: R) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Push notification
        this.actionEvent.next(AuthActionEvent.LOGIN);

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /**
   * Sign in using the access token
   */
  signInUsingRefreshToken(): Observable<boolean> {
    // this.accessToken = '';
    if (!this.refreshToken || AuthUtils.isTokenExpired(this.refreshToken)) {
      return of(false);
    }
    // Renew token
    return this.apiService.refreshToken(this.refreshToken).pipe(
      switchMap((response: RefreshTokenResponseDto) => {
        // Store the access token in the local storage
        this.accessToken = response.accessToken;

        // Store the refresh token in the local storage
        this.refreshToken = response.refreshToken;

        // Set the authenticated flag to true
        this.authenticated = true;

        // Return true
        return of(true);
      }),
      catchError(() =>
        // Return false
        of(false)
      )
    );
  }
  /**
   * Sign out
   */
  signOut(): void {
    // Remove the tokens from the local storage
    this.storage.removeItem('redoc_access_token');
    this.storage.removeItem('redoc_refresh_token');

    // Set the authenticated flag to false
    this.authenticated = false;

    // Push notification
    this.actionEvent.next(AuthActionEvent.LOGOUT);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated) {
      return of(true);
    }
    const accessToken = this.accessToken;
    // Check the access token availability
    if (!accessToken) {
      return of(false);
    }
    try {
      // if (this.enableSingleSignOn && this.cookieStorage) {
      //   // If using Single Sin On feature, check user is login on id.redoc
      //   const c_user = this.cookieStorage.getItem('redoc.c_user');
      //   console.log('check c_user', c_user);
      //   if (!c_user) {
      //     return of(false);
      //   }
      //   // If user has already available, compare c_user with current user info, which store on localStorage
      //   const { userId } = AuthUtils.decodeToken(accessToken);
      //   if (String(userId) !== this.cookieStorage.getItem('redoc.c_user')) {
      //     return of(false);
      //   }
      // }
      if (AuthUtils.isTokenExpired(accessToken)) {
        return this.signInUsingRefreshToken();
      }
    } catch (error) {
      this.signOut();
      return of(false);
    }
    return of(true);
  }

  forgotPassword(data: ForgotPasswordDto): Observable<unknown> {
    return this.apiService.forgotPassword(data);
  }
}
