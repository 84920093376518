import { Provider } from "@angular/core";
import { ENABLED_NORMALIZED_PARAMS_FN, EnabledNormalizedParamFnFeature, RedocApiFeatures, RedocApiFeatureKind, apiFeature } from "./api-feature";
import { IApiConfig, NormalizedApiParamsFn } from "./interfaces";
import { ApiService } from "./api.service";
import { API_CONFIG } from "./constants";

export function withNormalizedApiParamFn(fn:NormalizedApiParamsFn):EnabledNormalizedParamFnFeature{
  const providers:Provider[] = [
    {
      provide:ENABLED_NORMALIZED_PARAMS_FN,
      useValue:fn
    }
  ]
  return apiFeature(RedocApiFeatureKind.EnabledNormalizedParamFnFeature,providers)
}

export function provideApi(configs:IApiConfig,...apiFeatures:RedocApiFeatures[]){
  const providers:Provider[] = [
    ApiService,
    {
      provide: API_CONFIG,
      useValue: configs
    },
    apiFeatures.map(feature => feature.ɵproviders)
  ]
  return providers
}
