import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
export type UploadLinkPayload = Record<string,any> & {
  type?: string;
  name?: string;
  acl?: string;
}
export type PublicLinkPayload = Record<string,any> & {
  keyName: string;
}
export interface FilePayloadRequest {
  file: File;
  isPublic?: boolean;
  fields: Record<string, any>;
  url: string;
}
export type GetPublicLinkFn = <T>( data: PublicLinkPayload)=> Observable<T>;
export type GetUploadLinkFn= <T>(data: UploadLinkPayload & Record<string, any>)=> Observable<T>;
export const REDOC_MEDIA_STORAGE= new InjectionToken<any>('redMediaStorage');
export const GET_PUBLIC_LINK_FN = new  InjectionToken<GetPublicLinkFn>('GET_PUBLIC_LINK_FN');
export const GET_UPLOAD_LINK_FN = new  InjectionToken<GetUploadLinkFn>('GET_UPLOAD_LINK_FN');
