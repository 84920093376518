import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { ApplicationConfig, InjectionToken } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { AuthService } from '@id/data-access/auth';
import { UserEffects, UserFacade, fromUser } from '@id/data-access/user';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import {
  provideImageLoaderFn,
  provideMediaStorage,
  withPublicLinkFnFactory,
  withUploadLinkFnFactory,
} from '@redocco/material-ui';
import { provideLayout } from '@shared/components/layout';
import {
  provideApi,
  withHttpErrorInterceptor,
  withHttpErrorWrapperInterceptor,
} from '@shared/core/api';
import { ImageLoaderConfig, MediaStorageConfig } from '@shared/core/config';
import {
  provideSSRAuth,
  withAuthApiLoader,
  withAuthWithRefreshTokenInterceptor,
  withLoginRoute,
  withUserLoader,
} from '@shared/features/auth';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {provideGcaptcha} from '@shared/components/gcaptcha'
export interface AppTransferState {
  accessToken?: string;
  [x: string]: any;
}
export const APP_TRANSTER_STATE = new InjectionToken<AppTransferState>(
  'APP_TRANSTER_STATE'
);
export const appConfig: ApplicationConfig = {
  providers: [
    provideEffects(UserEffects),
    provideStore({
      [fromUser.USER_FEATURE_KEY]: fromUser.userReducer,
    }),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
      name: 'Redoc Identifier',
      // logOnly: !isDevMode()
    }),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideSSRAuth(
      withAuthApiLoader(AuthService),
      withUserLoader(UserFacade),
      withLoginRoute('sign-in')
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        withHttpErrorWrapperInterceptor(),
        withAuthWithRefreshTokenInterceptor(),
        withHttpErrorInterceptor(),
      ])
    ),
    provideApi({
      apiHost: environment.apiHost,
      urlPrefix: environment.apiPrefix,
      timeout: 90000,
      appId: environment.appId,
    }),
    provideMediaStorage(
      {
        storageDrive: environment.storageDrive,
        publicLink: 'storage/link',
      },
      withPublicLinkFnFactory(MediaStorageConfig.publicLinkFn),
      withUploadLinkFnFactory(MediaStorageConfig.uploadLinkFn)
    ),
    provideLayout(),
    provideImageLoaderFn(ImageLoaderConfig.imageLoaderFn),
    provideClientHydration(),
    provideAnimations(),
    // provideGcaptcha(environment.recaptchaSitekey)
  ],
};
