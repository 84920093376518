import { ClassProvider, Provider, Type } from '@angular/core';
import { LayoutService } from "./layout.service";
import { REDOC_LAYOUT_CONFIG } from "./layout.token";
import { LayoutConfig, layoutConfigDefault } from "./layout.types";
import {RedocMediaWatcherService,LayoutFeatures,ENABLED_DARK_MODE, EnabledDarkModeFeature, layoutFeature, LayoutFeatureKind} from "@shared/cdk/layout"
import { NavigationDataLoaderBaseService, NavigationDataLoaderDefaultService, VERTICAL_NAVIGATION_DATA_LOADER } from './layouts/vertical/vertical-navigation-data-loader.service';
/**
 * Sets up providers necessary to render layout for the application.
 *
 * @usageNotes
 *
 * Basic example of how you can add a Router to your application:
 * ```
 * const layoutConfig: LayoutConfig = {
 *   layout: 'classic',
 *   scheme: 'light',
 *   theme : 'default'
 * }
 * bootstrapApplication(AppComponent, {
 *   providers: [provideLayout(layoutConfig)]
 * });
 * ```
 *
 * @see `LayoutFeatures`
 *
 * @publicApi
 * @param configs Optional features to configure additional.
 * @returns A set of providers to setup a Layout Config.
 */
export function provideLayout(options?: {configs?: LayoutConfig,layoutFeatures?:LayoutFeatures[],navigationLoader?:Type<NavigationDataLoaderBaseService>}){
  return [
    RedocMediaWatcherService,
    LayoutService,
    {
      provide: REDOC_LAYOUT_CONFIG,
      useValue: options?.configs ?? layoutConfigDefault,
    },
    withNavigationDataLoader(options?.navigationLoader ?? NavigationDataLoaderDefaultService),
    options?.layoutFeatures?.map(feature => feature.ɵproviders) || []
  ]
}
export function withEnabledDarkMode():EnabledDarkModeFeature{
  const providers:Provider[] = [
    {
      provide:ENABLED_DARK_MODE,
      useValue:true
    }
  ]
  return layoutFeature(LayoutFeatureKind.EnabledDarkModeFeature,providers)
}

export function withNavigationDataLoader(instance: Type<NavigationDataLoaderBaseService>): ClassProvider {
  return{
    provide: VERTICAL_NAVIGATION_DATA_LOADER,
    useClass: instance,
  }
};
