import { InjectionToken, Provider } from "@angular/core";
import { NormalizedApiParamsFn } from "./interfaces";

/**
 * Helper type to represent a Router feature.
 *
 * @publicApi
 */
export interface RedocApiFeature<FeatureKind extends RedocApiFeatureKind> {
  ɵkind: FeatureKind;
  ɵproviders: Provider[];
}
/**
 * The list of features as an enum to uniquely type each feature.
 */
export const enum RedocApiFeatureKind {
  EnabledNormalizedParamFnFeature
}
export type EnabledNormalizedParamFnFeature = RedocApiFeature<RedocApiFeatureKind.EnabledNormalizedParamFnFeature>;
export type RedocApiFeatures = EnabledNormalizedParamFnFeature
export function apiFeature<FeatureKind extends RedocApiFeatureKind>(
  kind: FeatureKind, providers: Provider[]): RedocApiFeature<FeatureKind> {
return {ɵkind: kind, ɵproviders: providers};
}
const normalizeParamsDefault:NormalizedApiParamsFn = (data,options)=>data
export const ENABLED_NORMALIZED_PARAMS_FN= new InjectionToken<NormalizedApiParamsFn>('ENABLED_NORMALIZED_PARAMS_FN',{
  providedIn:'root',
  factory:()=> normalizeParamsDefault
})
