import { HttpConstants } from '@agent/constants';
import { Injectable } from '@angular/core';
import { ApiService, IApiQuery } from '@shared/core/api';
import { IOrganization } from '@shared/data-access/interfaces';
import { OrganizationModel } from '@shared/data-access/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrganizationApiService {
    static ORGANIZATION = 'organizations';
    static VERIFY_INVITE_TOKEN = 'organizations/verify';
    static ORGANIZATION_SETTINGS = 'organizations/settings';
    static SUMMARY = 'organizations/summary';
    static ORGANIZATION_MODULE = 'organizations/module/with-action';
    static ORGANIZATION_USERS = 'organizations/user';
    static INVITE_ORGANIZATION_USER = 'organizations/user/invite';
    static CREATE_BUSINESS_UNIT = 'organizations/business-unit';
    static GET_ALL_BUSINESS_UNIT = 'organizations/business-unit';
    static GROUP_LIST_ORGANIZATION = 'organizations/group';
    static GET_DETAIL_BUSINESS_UNIT = 'organizations/business-unit';
    static GET_USERS_OF_BUSINESS_UNIT = 'organizations/business-unit/:businessUnitId/users';
    static REGISTER = 'organizations/register';
    static ZOOM_VERIFY = 'video-call/zoom/verify';

    static SWITCH_ORG = 'organizations/switch';
    static GET_ALL_ORGANIZATION = 'organizations/*';
    static ORGANIZATION_SETTINGS_V2 = 'organizations-settings';

    constructor(private apiService: ApiService) { }

    verifyInviteToken(token:string) {
        return this.apiService.post(
            OrganizationApiService.VERIFY_INVITE_TOKEN,
            token
        );
    }

    getMyOrganization(): Observable<OrganizationModel> {
        return this.apiService
            .get(OrganizationApiService.ORGANIZATION, {}, {
                exposeHeaders: {
                    [HttpConstants.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['403']
                }
            })
            .pipe(map(data => OrganizationModel.fromJson(data)));
    }

    // update(data): Observable<OrganizationModel> {
    //     return this.apiService.patch(OrganizationApiService.ORGANIZATION, data).pipe(map(res => OrganizationModel.fromJson(res)));
    // }

    // getSummary() {
    //     return this.apiService.get(OrganizationApiService.SUMMARY);
    // }

    // getOrganizationModules() {
    //     return this.apiService.post(OrganizationApiService.ORGANIZATION_MODULE);
    // }

    // getOrganizationUsers(query) {
    //     return this.apiService.get(
    //         OrganizationApiService.ORGANIZATION_USERS,
    //         query
    //     );
    // }

    // inviteOrganizationUser(data) {
    //     return this.apiService.post(
    //         OrganizationApiService.INVITE_ORGANIZATION_USER,
    //         data
    //     );
    // }

    // createBusinessUnit(data) {
    //     return this.apiService.post(
    //         OrganizationApiService.CREATE_BUSINESS_UNIT,
    //         data
    //     );
    // }

    // updateOrganizationSettings(data) {
    //     return this.apiService.post(
    //         OrganizationApiService.ORGANIZATION_SETTINGS,
    //         data
    //     );
    // }

    // getAllBusinessUnit() {
    //     return this.apiService.get(
    //         OrganizationApiService.GET_ALL_BUSINESS_UNIT
    //     );
    // }

    // getOrganizationGroups() {
    //     return this.apiService.get(
    //         OrganizationApiService.GROUP_LIST_ORGANIZATION
    //     );
    // }

    // getBusinessUnitDetail(businessUnitId) {
    //     return this.apiService.get(
    //         OrganizationApiService.GET_DETAIL_BUSINESS_UNIT +
    //         '/' +
    //         businessUnitId
    //     );
    // }

    // updateBusinessUnit(businessUnitId, data) {
    //     return this.apiService.put(
    //         OrganizationApiService.CREATE_BUSINESS_UNIT + '/' + businessUnitId,
    //         data
    //     );
    // }

    // getUsersOfBusinessUnit(businessUnitId, query) {
    //     query.businessUnitId = businessUnitId;
    //     return this.apiService.get(
    //         OrganizationApiService.GET_USERS_OF_BUSINESS_UNIT,
    //         query
    //     );
    // }
    // register(data): Observable<OrganizationModel> {
    //     return this.apiService.post(OrganizationApiService.REGISTER, data).pipe(
    //         map(
    //             (res: OrganizationInterface) => OrganizationModel.fromJson(res)
    //         ));
    // }

    // zoomVerify(data: { zoomApiKey: string; zoomSecretKey }) {
    //     return this.apiService.post(OrganizationApiService.ZOOM_VERIFY, data);
    // }

    switch(data: { orgId: number; buId: number; }) {
        return this.apiService.post(
            OrganizationApiService.SWITCH_ORG,
            data
        );
    }
    getAll(): Observable<OrganizationModel[]> {
      return this.getWithQuery()

    }
    getWithQuery(queryParams:IApiQuery={}): Observable<OrganizationModel[]> {
        return this.apiService
            .get(OrganizationApiService.GET_ALL_ORGANIZATION, queryParams, {
                exposeHeaders: {
                    [HttpConstants.HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['403']
                }
            })
            .pipe(map((data:IOrganization[]) => data.map(v => OrganizationModel.fromJson(v))));

    }

    // getOrganizationSettings() {
    //     return this.apiService.get(OrganizationApiService.ORGANIZATION_SETTINGS_V2);
    // }
}
