import { BaseModel, Default, Field, Type } from '@redocco/core';
import { IOrganization, IOrganizationMetadata } from '../interfaces';
import { BusinessUnitModel } from './business-unit.model';

export class OrganizationMetadataModel
  extends BaseModel
  implements Required<IOrganizationMetadata>
{
  @Field()
  industryId!: number;
  @Field()
  companySize!: string;
  // @Field()
  // @Default(new SalespersonDataModel())
  // @Type(() => SalespersonDataModel)
  // agencyInfo: SalespersonDataModel;
  // @Field()
  // phone: PhoneNumber;
  // @Field()
  // ownerInfo: {
  //     phoneNumber: PhoneNumber;
  // };
  // @Field()
  // note: string;
  // @Field()
  // gstRegNo: string;
}

export class OrganizationModel
  extends BaseModel
  implements Required<IOrganization>
{
  @Field()
  id!: number;
  @Field()
  name!: string;
  @Field()
  status!: string;
  @Field()
  logo!: string;
  @Field()
  favicon!: string;
  @Field()
  email!: string;
  @Field()
  address!: string;
  @Field()
  phone!: string;
  @Field()
  website!: string;
  @Field()
  @Default(0)
  maximumBusinessUnit!: number;
  @Field()
  ownerId!: number;
  @Field()
  @Type(BusinessUnitModel)
  businessUnits!: BusinessUnitModel[];
  @Field()
  metadata!: IOrganizationMetadata;
}
