import {
  Route
} from '@angular/router';
import { IdLayoutComponent } from '@id/core/layout';
import { AuthGuard, NoAuthGuard, RedirectGuard, provideRedirectGuard } from '@shared/features/auth';
import { environment } from '../environments/environment';
const DASHBOARD = 'dashboad';
export const appRoutes: Route[] = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: DASHBOARD,
  // },
  // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: DASHBOARD },
  {
    path: '',
    pathMatch: 'full',
    providers: [
      provideRedirectGuard(environment.authConfig.defaultAppUrl)
    ],
    canActivate: [
      RedirectGuard
    ],
    component: IdLayoutComponent
  },
  {
    path: '',
    component: IdLayoutComponent,
    // canActivate: [
    //   (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    //     inject(NoAuthGuard).canActivate(route, state),
    // ],
    // canActivateChild: [
    //   (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    //     inject(NoAuthGuard).canActivateChild(childRoute, state),
    // ],
    data: {
      layout: 'wellcome',
    },
    children: [
      {
        path: 'sign-in',
        loadComponent: () =>
          import('./modules/auth/sign-in/sign-in.component').then(
            (m) => m.SignInComponent
          ),
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import(
            './modules/auth/forgot-password/forgot-password.component'
          ).then((m) => m.ForgotPasswordComponent),
      },
      {
        path: 'activate',
        loadComponent: () =>
          import(
            './modules/auth/activate-account/activate-account.component'
          ).then((m) => m.ActivateAccountComponent),
      },
    ],
    providers: [NoAuthGuard],
  },
  {
    path: '',
    component: IdLayoutComponent,
    // canActivate: [
    //   (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    //     inject(AuthGuard).canActivate(route, state),
    // ],
    // canActivateChild: [
    //   (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
    //     inject(AuthGuard).canActivateChild(childRoute, state),
    // ],
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'scopes',
        loadComponent: () =>
          import('./modules/auth/scope/scope.component').then(
            (m) => m.ScopeComponent
          ),
      },
      // {
      //   path: DASHBOARD,
      //   loadComponent: () =>
      //     import('./modules/dashboard/dashboard.component').then(
      //       (m) => m.DashboardComponent
      //     ),
      // },
    ],
    providers: [AuthGuard],
  },
  // {
  //   path: '',
  //   component: IdLayoutComponent,
  //   // canActivate: [
  //   //   (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  //   //     inject(SLOAuthGuard).canActivate(route, state),
  //   // ],
  //   // canActivateChild: [
  //   //   (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  //   //     inject(SLOAuthGuard).canActivateChild(childRoute, state),
  //   // ],
  //   data: {
  //     layout: 'empty',
  //   },
  //   children: [
  //     {
  //       path: 'sign-out',
  //       loadComponent: () =>
  //         import('./modules/auth/sign-out/sign-out.component').then(
  //           (m) => m.SignOutComponent
  //         ),
  //     },
  //   ],
  //   // providers: [SLOAuthGuard],
  // },
];
