<div
  class="redoc-vertical-navigation-item-wrapper"
  [class.redoc-vertical-navigation-item-has-subtitle]="!!item.subtitle"
  [ngClass]="item.classes?.wrapper"
>
  <div
    class="redoc-vertical-navigation-item"
    [ngClass]="{
      'redoc-vertical-navigation-item-disabled': item.disabled,
      'redoc-vertical-navigation-item--has-child-active': isActived
    }"
    [matTooltip]="item.tooltip || ''"
    (click)="toggleCollapsable()"
  >
    <!-- Icon -->
    <ng-container *ngIf="item.icon; else noIcon">
      <mat-icon
        *ngIf="item.isSvgIcon"
        [color]="isActived ? 'primary' : 'default'"
        class="redoc-vertical-navigation-item-icon"
        [ngClass]="item.classes?.icon!"
        [svgIcon]="item.icon"
      ></mat-icon>
      <mat-icon
        *ngIf="!item.isSvgIcon"
        [color]="isActived ? 'primary' : 'default'"
        class="redoc-vertical-navigation-item-icon"
        [ngClass]="item.classes?.icon"
        >{{ item.icon }}</mat-icon
      >
    </ng-container>
    <ng-template #noIcon>
      <!-- <div class="redoc-vertical-navigation-item-dot-icon-wrapper">
              <div class="flex justify-center items-center bg-white w-full h-full">
                <div class="bg-primary redoc-vertical-navigation-item-dot-icon"></div>
              </div>
          </div> -->
    </ng-template>

    <!-- Title & Subtitle -->
    <div class="redoc-vertical-navigation-item-title-wrapper">
      <div class="redoc-vertical-navigation-item-title">
        <span [ngClass]="item.classes?.title">
          {{ item.title }}
        </span>
      </div>
      <ng-container *ngIf="item.subtitle">
        <div class="redoc-vertical-navigation-item-subtitle">
          <span [ngClass]="item.classes?.subtitle">
            {{ item.subtitle }}
          </span>
        </div>
      </ng-container>
    </div>

    <!-- Badge -->
    <ng-container *ngIf="item.badge">
      <div class="redoc-vertical-navigation-item-badge">
        <div
          class="redoc-vertical-navigation-item-badge-content"
          [ngClass]="item.badge.classes!"
        >
          {{ item.badge.title }}
        </div>
      </div>
    </ng-container>

    <!-- Arrow -->
    <mat-icon class="redoc-vertical-navigation-item-arrow icon-size-4"
      >expand_more</mat-icon
    >
  </div>
</div>

<div
  class="redoc-vertical-navigation-item-children"
  *ngIf="!isCollapsed"
  @expandCollapse
>
  <ng-container
    *ngFor="let item of item.children; let i = index; trackBy: trackByFn"
  >
    <!-- Skip the hidden items -->
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
      <!-- Basic -->
      <ng-container *ngIf="item.type === 'basic'">
        <redoc-vertical-navigation-basic-item [item]="item" [name]="name">
          <div directionIcon class="absolute left-5 bottom-4">
            <div class="direction-icon" [style.height.px]="46 * (i + 1)"></div>
            <!-- <svg
              class="redoc-vertical-navigation-item-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="35"
              viewBox="0 0 27 35"
              fill="none"
            >
              <path
                d="M12 1V15C12 19.4183 15.5817 23 20 23H26"
                stroke="currentColor"
                stroke-linecap="round"
              />
            </svg> -->
          </div>
        </redoc-vertical-navigation-basic-item>
      </ng-container>

      <!-- Collapsable -->
      <ng-container *ngIf="item.type === 'collapsable'">
        <redoc-vertical-navigation-collapsable-item
          [item]="item"
          [name]="name"
          [autoCollapse]="autoCollapse"
        ></redoc-vertical-navigation-collapsable-item>
      </ng-container>

      <!-- Divider -->
      <ng-container *ngIf="item.type === 'divider'">
        <redoc-vertical-navigation-divider-item
          [item]="item"
          [name]="name"
        ></redoc-vertical-navigation-divider-item>
      </ng-container>

      <!-- Group -->
      <ng-container *ngIf="item.type === 'group'">
        <redoc-vertical-navigation-group-item
          [item]="item"
          [name]="name"
        ></redoc-vertical-navigation-group-item>
      </ng-container>

      <!-- Spacer -->
      <ng-container *ngIf="item.type === 'spacer'">
        <redoc-vertical-navigation-spacer-item
          [item]="item"
          [name]="name"
        ></redoc-vertical-navigation-spacer-item>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
