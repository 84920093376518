/* eslint-disable @typescript-eslint/no-explicit-any */
import { TransformationType } from '../interfaces';
import { ClassConstructor } from '../interfaces/class-constructor.interface';
import { ClassTransformOptions } from '../interfaces/class-transform-options.interface';
import { BaseExecutor } from './base.executor';
import { PlainToClassExecutor } from './plain-to-class.executor';

export class Executors {
  static plugins: ClassConstructor<BaseExecutor>[] = [];
  availablePlugins: Map<TransformationType, BaseExecutor> = new Map<
    TransformationType,
    BaseExecutor
  >();
  constructor() {
    const plugins = Executors.plugins;
    for (let index = 0; index < plugins.length; index++) {
      const element = new plugins[index]();
      if (!this.availablePlugins.has(element.type)) {
        this.availablePlugins.set(element.type, element);
      }
    }
  }
  transform<T extends Record<string, any>>(
    transformationType: TransformationType,
    source: Record<string, any> | Record<string, any>[] | any,
    value: Record<string, any> | Record<string, any>[] | any,
    targetType: ClassConstructor<T>,
    options?: ClassTransformOptions
  ): T {
    const selected = this.availablePlugins.get(transformationType);
    if (selected && selected.canExecute(transformationType)) {
      return selected.execute(source, value, targetType, options);
    }
    throw new Error(
      `Can not found transformationType is ${transformationType}`
    );
  }
}
Executors.plugins = [PlainToClassExecutor];
