import {
  EnvironmentProviders,
  Provider,
  makeEnvironmentProviders,
} from '@angular/core';
import {
  AUTH_COOKIE_READER,
  AUTH_SSO_ENABLE,
  AUTH_STORAGE,
  AuthFeatureKind,
  AuthFeatures,
  withAuthStorage,
} from './auth-feature';
import { AuthWithRefreshTokenInterceptorHandler } from './auth-with-refresh-token.interceptor';
import { AuthProvider } from './auth.service';
import { CookieServerReaderService } from './cookie-server.service';
import { RedocAuthCookieStorage, RedocAuthLocalStorage } from './storages';
import { AuthSSRProvider } from './auth-ssr.service';
import { CookieReaderService } from './cookie.service';
import { AUTH_PROVIDER } from './auth.token';
import { AuthTransferStateStorage } from './storages/transfer-state.storage';
import {
  AUTH_NAVIGATION_HANDLER,
  AuthNavigationHandler,
  ServerAuthNavigationHandler,
} from './auth-navigation-handler';

export function provideAuth(
  ...authFeatures: AuthFeatures[]
): EnvironmentProviders {
  if (
    authFeatures.every(
      (feat) => [AuthFeatureKind.EnabledAuthStorage].indexOf(feat.ɵkind) === -1
    )
  ) {
    authFeatures.push(withAuthStorage(RedocAuthLocalStorage));
  }
  const providers: Provider[] = [
    {
      provide: AUTH_PROVIDER,
      useClass: AuthProvider,
    },
    {
      provide: AUTH_NAVIGATION_HANDLER,
      useClass: AuthNavigationHandler,
    },
    AuthWithRefreshTokenInterceptorHandler,
    authFeatures.map((feature) => feature.ɵproviders),
  ];
  return makeEnvironmentProviders(providers);
}
export function provideSSOAuth(
  ...authFeatures: AuthFeatures[]
): EnvironmentProviders {
  const providers: Provider[] = [
    {
      provide: AUTH_PROVIDER,
      useClass: AuthProvider,
    },
    {
      provide: AUTH_NAVIGATION_HANDLER,
      useClass: AuthNavigationHandler,
    },
    {
      provide: AUTH_SSO_ENABLE,
      useValue: true,
    },
    AuthWithRefreshTokenInterceptorHandler,
    {
      provide: AUTH_STORAGE,
      useClass: RedocAuthLocalStorage,
    },
    RedocAuthCookieStorage,
    {
      provide: AUTH_COOKIE_READER,
      useClass: CookieReaderService,
    },
    authFeatures
      .filter((feature) => feature.ɵkind !== AuthFeatureKind.EnabledAuthStorage)
      .map((feature) => feature.ɵproviders),
  ];
  return makeEnvironmentProviders(providers);
}
export function provideSSRAuth(
  ...authFeatures: AuthFeatures[]
): EnvironmentProviders {
  const providers: Provider[] = [
    {
      provide: AUTH_PROVIDER,
      useClass: AuthSSRProvider,
    },

    {
      provide: AUTH_NAVIGATION_HANDLER,
      useClass: AuthNavigationHandler,
    },
    AuthWithRefreshTokenInterceptorHandler,
    {
      provide: AUTH_STORAGE,
      useClass: RedocAuthLocalStorage,
    },
    RedocAuthCookieStorage,
    {
      provide: AUTH_COOKIE_READER,
      useClass: CookieReaderService,
    },
    authFeatures
      .filter((feature) => feature.ɵkind !== AuthFeatureKind.EnabledAuthStorage)
      .map((feature) => feature.ɵproviders),
  ];
  return makeEnvironmentProviders(providers);
}
export function provideSSRAuthServer(): EnvironmentProviders {
  const providers: Provider[] = [
    {
      provide: AUTH_STORAGE,
      useClass: AuthTransferStateStorage,
    },

    {
      provide: AUTH_NAVIGATION_HANDLER,
      useClass: ServerAuthNavigationHandler,
    },
    {
      provide: AUTH_COOKIE_READER,
      useClass: CookieServerReaderService,
    },
  ];
  return makeEnvironmentProviders(providers);
}
