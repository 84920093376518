import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_CONFIG } from "./constants";
import { IApiConfig, IApiDTO, IApiOption, NormalizedApiParamsFn } from "./interfaces";
import { Observable, map, timeout } from "rxjs";
import { ENABLED_NORMALIZED_PARAMS_FN } from "./api-feature";

@Injectable()
export class ApiService {
  headers: HttpHeaders = new HttpHeaders({
    // 'Content-Type': 'application/json',
    source: 'RedocApiService', // Entity Name
  });
  constructor(
    @Inject(API_CONFIG) private apiConfig: IApiConfig,
    @Inject(ENABLED_NORMALIZED_PARAMS_FN) private normalizedParamFn:NormalizedApiParamsFn,
    private httpClient: HttpClient
  ) {}
  private defaultOptions: IApiOption = {
    loader: true,
    pretreatmentResponse: true,
    excludeFields: [],
    requestOptions: {
      headers: this.headers,
    },
  };
   pretreatmentDataRequest(data: Record<string, any>, options?: IApiOption) {
    return this.normalizedParamFn(data,options)
    // if (data instanceof FormData) {
    //   return data;
    // }
    // data = ApiService.formatMomentObject(data);
    // if (options?.removeEmpty?.enable) {
    //   data = Helper.removeEmpty(data, options?.removeEmpty?.excludeFields);
    // }

    // return data;
  }

  // static formatMomentObject(data: any): any {
  //   if (isMoment(data)) {
  //     return data.format();
  //   }
  //   if (isArray(data)) {
  //     return data.map(item => ApiService.formatMomentObject(item));
  //   }
  //   if (isObject(data)) {
  //     const res: { [x in string]: any } = { ...data };
  //     const keys = Object.keys(res);
  //     keys.forEach(key => {
  //       res[key] = ApiService.formatMomentObject(res[key]);
  //     });
  //     return res;
  //   }
  //   return data;
  // }

  static normalizeOption(options: IApiOption, optionsMatching?: IApiOption): IApiOption {
    if (!optionsMatching) {
      return options;
    }
    let results: IApiOption = { ...options };
    optionsMatching.requestOptions = { ...results.requestOptions, ...optionsMatching.requestOptions };
    results = { ...results, ...optionsMatching };
    if (optionsMatching.exposeHeaders) {
      Object.keys(optionsMatching.exposeHeaders).forEach(key => {
        const headers = results.requestOptions?.headers as HttpHeaders;
        if (optionsMatching.exposeHeaders && results.requestOptions) {
          results.requestOptions.headers = headers.set(key, optionsMatching.exposeHeaders[key]);
        }
      });
    }
    return results;
  }

  createAPIURL(url: string, params: Record<string, string>): string {
    if (params instanceof FormData) {
      return this.apiConfig.apiHost + this.apiConfig.urlPrefix + url;
    }
    const paths = url.split('/');
    paths.forEach((path, i) => {
      if (path.startsWith(':')) {
        const key = path.slice(1);

        paths[i] = params[key];
        delete params[key];
      }
    });

    return [this.apiConfig.apiHost, this.apiConfig.urlPrefix , ...paths].join('/');
  }

  get(url: string, params?: HttpParams | any, options?: IApiOption): Observable<any> {
    params = this.pretreatmentDataRequest(params, options);
    const defaultRequestOptions = {
      ...this.defaultOptions,
      ...{
        requestOptions: {
          params: params,
          headers: this.headers,
        },
      },
    };
    options = ApiService.normalizeOption(defaultRequestOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.get<IApiDTO>(fullUrl, options.requestOptions).pipe(
      timeout(this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response.data : response))
    );
  }

  post(url: string, params?: any, options?: IApiOption): Observable<any> {
    params = this.pretreatmentDataRequest(params, options);
    options = ApiService.normalizeOption(this.defaultOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.post<IApiDTO>(fullUrl, params, options.requestOptions).pipe(
      timeout(this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response.data : response))
    );
  }

  put(url: string, params?: any, options?: IApiOption): Observable<any> {
    params = this.pretreatmentDataRequest(params, options);
    options = ApiService.normalizeOption(this.defaultOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.put<IApiDTO>(fullUrl, params, options.requestOptions).pipe(
      timeout(this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response.data : response))
    );
  }

  patch(url: string, params?: any, options?: IApiOption): Observable<any> {
    params = this.pretreatmentDataRequest(params, options);
    options = ApiService.normalizeOption(this.defaultOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.patch<IApiDTO>(fullUrl, params, options.requestOptions).pipe(
      timeout(this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response.data : response))
    );
  }

  delete(url: string, params?: any, options?: IApiOption): Observable<any> {
    params = this.pretreatmentDataRequest(params, options);
    const defaultRequestOptions = {
      ...this.defaultOptions,
      ...{
        requestOptions: {
          body: params,
          headers: this.headers,
        },
      },
    };
    options = ApiService.normalizeOption(defaultRequestOptions, options);
    const fullUrl = this.createAPIURL(url, params);
    return this.httpClient.request<IApiDTO>('delete', fullUrl, options.requestOptions).pipe(
      timeout(this.apiConfig.timeout),
      map(response => (options?.pretreatmentResponse ? response['data'] : response))
    );
  }
}
