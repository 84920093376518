import { InjectionToken, Provider } from "@angular/core";

/**
 * Helper type to represent a Router feature.
 *
 * @publicApi
 */
export interface LayoutFeature<FeatureKind extends LayoutFeatureKind> {
  ɵkind: FeatureKind;
  ɵproviders: Provider[];
}
/**
 * The list of features as an enum to uniquely type each feature.
 */
export const enum LayoutFeatureKind {
  EnabledDarkModeFeature
}
export type EnabledDarkModeFeature = LayoutFeature<LayoutFeatureKind.EnabledDarkModeFeature>;
export type LayoutFeatures = EnabledDarkModeFeature
export function layoutFeature<FeatureKind extends LayoutFeatureKind>(
  kind: FeatureKind, providers: Provider[]): LayoutFeature<FeatureKind> {
return {ɵkind: kind, ɵproviders: providers};
}

export const ENABLED_DARK_MODE = new InjectionToken<boolean>('ENABLED_DARK_MODE',{
  providedIn:'root',
  factory:()=> false
})
