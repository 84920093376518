/* eslint-disable @typescript-eslint/no-explicit-any */
import { plainToClass } from './core/plan-to-class';
import { TransformFnParams } from './interfaces';
import { ClassConstructor } from './interfaces/class-constructor.interface';
import { Transform } from './transform.decorator';

export function Type(targetType: ClassConstructor<any>) {
  return Transform({
    transform: (params) => {
      return transfromValue(targetType, params);
    },
  });
}
function transfromValue(
  targetType: ClassConstructor<any>,
  params: TransformFnParams
): any {
  const { value: currentValue } = params;
  if (currentValue === undefined) {
    return undefined;
  }
  if (Array.isArray(currentValue)) {
    return currentValue.map((item) => {
      return transfromValue(targetType, {...params, obj: currentValue, value: item });
    });
  }
  if (targetType === Number) {
    return Number(currentValue);
  }
  if (targetType === String) {
    return String(currentValue);
  }
  if (targetType === Boolean) {
    return Boolean(currentValue);
  }
  if (targetType === Date) {
    if (currentValue instanceof Date) {
      return new Date(currentValue.valueOf());
    }
    if (currentValue === null || currentValue === undefined)
      return currentValue;
    return new Date(currentValue);
  }
  if (typeof currentValue === 'object' && currentValue !== null) {
    return plainToClass(targetType, currentValue);
  }
  return currentValue;
}
