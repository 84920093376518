/* eslint-disable @typescript-eslint/no-explicit-any */
// export const Transform = TransformFn;
// import { defaultMetadataStorage } from './core/storage';
// import { TransformFnParams } from './interfaces';

import { TransformFnParams } from './interfaces';
import { makePropDecorator } from './util';

// export function Transform(
//   transformFn: (params: TransformFnParams) => any
//   // options: TransformOptions = {}
// ): PropertyDecorator {
//   return function (target: any, propertyName: string | symbol): void {
//     defaultMetadataStorage.addTransformMetadata({
//       target: target.constructor,
//       propertyName: propertyName as string,
//       transformFn,
//       // options,
//     });
//   };
// }
export interface Transform {
  /**
   * The name of the DOM property to which the input property is bound.
   */
  alias?: string;

  /**
   * Whether the input is required for the directive to function.
   */
  optional?: boolean;

  /**
   * Function with which to transform the input value before assigning it to the directive instance.
   */
  transform?: (params: TransformFnParams) => any;
}
export interface TransformDecorator {
  (arg?: string | Transform): any;
  new (arg?: string | Transform): any;
}
export const Transform: TransformDecorator = makePropDecorator(
  'Transform',
  (arg?: string | { alias?: string; optional?: boolean }) => {
    if (!arg) {
      return {};
    }
    return typeof arg === 'string' ? { alias: arg } : arg;
  }
);
