import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { AUTH_COOKIE_READER } from '../auth-feature';
import {
  RedocAuthStorage,
  RedocAuthStorageOptions,
  RedocAuthStorageType,
} from './storage.interface';

@Injectable()
export class RedocAuthCookieStorage implements RedocAuthStorage {
  type: RedocAuthStorageType = 'cookie';
  // cookieService = inject(CookieService);
  reader = inject(AUTH_COOKIE_READER);
  platformId = inject(PLATFORM_ID);
  setItem(key: string, value: string, options?: RedocAuthStorageOptions): void {
    // if (isPlatformServer(this.platformId)) {
    //   return;
    //   console.log('heelo server setItem', key, value);
    // }
    // this.reader.put(key, value, options);
  }
  getItem(key: string): string | null {
    return this.reader.get(key) || null;
  }
  removeItem(key: string): void {
    // this.cookieService.remove(key);
  }
  clear(): void {
    // this.cookieService.removeAll();
  }
}
