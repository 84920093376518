import { BaseModel, Field, Transform } from '@redocco/core';
import { IUserAccount } from '../interfaces';

export class UserAccountModel extends BaseModel implements IUserAccount {
  @Field()
  id!: number;

  @Field()
  firstName!: string;

  @Field()
  lastName!: string;

  @Field()
  @Transform({
    transform: ({ obj }: { obj: IUserAccount }) => {
      return [obj.firstName, obj.lastName].join(' ').trim();
    },
  })
  name!: string;

  @Field()
  email!: string;

  @Field({ optional: true })
  username?: string;

  @Field()
  avatar!: string;

  @Field()
  profile!: {
    subscription:{
      planId:number;
      interval:"month"
    }
  };
}
