import { Injectable, InjectionToken, inject } from '@angular/core';
import { Router } from '@angular/router';
import { REQUEST, RESPONSE } from '@shared/core/express';
interface QueryParams {
  [key: string]: any;
}

export const AUTH_NAVIGATION_HANDLER =
  new InjectionToken<AuthNavigationHandler>('AUTH_NAVIGATION_HANDLER');
@Injectable()
export class AuthNavigationHandler {
  _router = inject(Router);
  navigate(route: string[], options?: { queryParams?: QueryParams }): void {
    this._router.navigate(route, options);
  }
  navigateByUrl(url:string){
    return this._router.navigateByUrl(url)
  }
}

@Injectable()
export class ServerAuthNavigationHandler {
  private response = inject(RESPONSE, { optional: true });
  private request = inject(REQUEST);
  navigate(route: string[], options?: { queryParams?: QueryParams }): void {
    const baseUrl = this.request.protocol + '://' + this.request.get('host');
    const queryParams = options?.queryParams || {};
    const urlSearchParam = new URLSearchParams(queryParams);
    const url = new URL(route.join('/'), baseUrl);
    url.search = urlSearchParam.toString();
    console.log('url.href -->', url.href);
    this.response?.redirect(url.href);
  }
}
