import { STORAGE_TYPE } from '@redocco/material-ui';

export const environment = {
  baseUrl: 'https://id-staging.redoc.co',
  appClientDefaultUrl: 'https://agent-staging.redoc.co',
  production: false,
  apiHost: 'https://api-staging.redoc.co',
  recaptchaSitekey: "6Le-KEIoAAAAAACItuo5OUNkQcgUDkFBhkoAi813",
  storageMediaUrl: 'https://huttonsstoragedev.blob.core.windows.net',
  storageDrive: STORAGE_TYPE.AZURE,
  apiPrefix: 'api',
  appId: 'default',
  defaultTimeZone: 'Asia/Singapore',
  ingeniqueApiConfig: {
    token:
      'eyJhbGciOiJIUzUxMiJ9.eyJhY2NvdW50SWQiOiIzMDBsaXZlIiwiaWF0IjoxNjcwNTY4ODc4LCJ1c2VySWQiOiI5MDkifQ.hpDdfxfmSKI7YB8yYBUz0pj-ONNJxoyyTqks6nxib9WHF1kTzbf97mE8OXOvrhNWOBbrfDqiI86Ecyx4-ZPBAw',
    apiKey: 'oo7KUatYqm3iLra6MhLta2BAeh6lIpUH9aoBTXg8',
    sectorId: 'SGDJ',
    accountName: '300live',
  },
  authConfig: {
    sessionPrefix: 'staging',
    domain: '.redoc.co',
    defaultAppUrl: 'https://agent-staging.redoc.co',
  },
};
