import { Inject, Injectable, InjectionToken, Optional, } from '@angular/core';
import { ActivatedRouteSnapshot,  Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthProvider } from '../auth.service';
export const ORIGIN_URL_REDIRECT = new InjectionToken<string>('ORIGIN_URL_REDIRECT');

@Injectable()
export class RedirectGuard  {
  /**
   * Constructor
   */
  constructor(private _authService: AuthProvider, private _router: Router, @Optional() @Inject(ORIGIN_URL_REDIRECT) private baseUrl?: string) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(route, redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(childRoute, redirectUrl);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(route: ActivatedRouteSnapshot, redirectURL: string): Observable<boolean> {

    const url = [this.baseUrl, redirectURL].filter(path => !!path).join('/')
    return of(false).pipe(

      tap(canAccess => {
        location.href = url
      })
    );
  }
}

export function provideRedirectGuard(originRedirectUrl?: string) {
  const providers = [
    {
      provide: ORIGIN_URL_REDIRECT,
      useValue: originRedirectUrl
    },
    RedirectGuard
  ]
  return providers
}