import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PortalModule } from '@angular/cdk/portal';
import { UserComponent } from './user.component';
import { UserItemContextDirective } from './user-context.directive';

@NgModule({
  declarations: [UserComponent, UserItemContextDirective],
  imports: [CommonModule, MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, PortalModule],
  exports: [UserComponent, UserItemContextDirective],
})
export class UserModule {}
