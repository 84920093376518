import { Injectable } from '@angular/core';
import { ApiService } from '@shared/core/api';
import {
  ForgotPasswordDto,
  LoginDto,
  LoginResponseDto,
  RefreshTokenResponseDto,
} from '@shared/data-access/dtos';
import { UserAccountModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  static ROOT_POINT = 'auth';
  static REFRESH_TOKEN = 'auth/access-token';
  static RESET_PASSWORD = 'auth/reset-password';
  static FORGOT_PASSWORD = 'auth/forgot-password';

  constructor(private apiService: ApiService) {}

  getMe(): Observable<UserAccountModel> {
    return this.apiService
      .get(AuthApiService.ROOT_POINT)
      .pipe(map((res) => UserAccountModel.fromJson(res)));
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    return this.apiService.post(AuthApiService.ROOT_POINT, credentials);
  }

  /**
   * Refresh token
   *
   * @param credentials
   */
  refreshToken(refreshToken: string): Observable<RefreshTokenResponseDto> {
    return this.apiService.post(AuthApiService.REFRESH_TOKEN, { refreshToken });
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: { password: string }): Observable<any> {
    return this.apiService.post(AuthApiService.RESET_PASSWORD, data);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(data: ForgotPasswordDto): Observable<any> {
    return this.apiService.post(AuthApiService.FORGOT_PASSWORD, data);
  }
}
