import { BaseModel, Field } from '@redocco/core';
import { IPaymentInfo } from '../interfaces/payment-info.interface';

export class PaymentInfoModel extends BaseModel implements IPaymentInfo {
  @Field({ optional: true })
  qrcode?: string;

  @Field()
  bankName!: string;

  @Field()
  accountHolder!: string;

  @Field()
  accountNumber!: string;

  @Field()
  description!: string;
}
