import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { ImageLoaderModule } from '@redocco/material-ui';
import { UserModule } from '@shared/components/auth';
import {
  ClassicLayoutComponent,
  EmptyLayoutComponent,
  LayoutBase,
  LogoHeaderDirective,
  VerticalGroupActionHeaderDirective,
  WellcomeLayoutComponent,
} from '@shared/components/layout';
import { AUTH_PROVIDER } from '@shared/features/auth';

export const layoutComponents = [
  // Empty
  EmptyLayoutComponent,
  // Wellcome
  WellcomeLayoutComponent,

  // Horizontal navigation

  // Vertical navigation
  ClassicLayoutComponent,
];
export const components = [
  VerticalGroupActionHeaderDirective,
  LogoHeaderDirective,
  RouterModule,
  ImageLoaderModule,
  MatDividerModule,
  UserModule,
  MatIconModule,
  MatMenuModule,
  MatButtonModule,
];
@Component({
  selector: 'redoc-layout',
  standalone: true,
  imports: [CommonModule, ...layoutComponents, ...components],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IdLayoutComponent extends LayoutBase implements OnInit {
  private _authProvider = inject(AUTH_PROVIDER);
  /**
   * Sign out
   */
  signOut(): void {
    this._authProvider.signOut();
  }

  profile(): void {
    // this._router.navigate([ROUTE.PERSONAL_INFO.PATH]);
  }
}
