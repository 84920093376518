import { EnvironmentProviders, Provider, makeEnvironmentProviders } from "@angular/core";
import { REDOC_MEDIA_STORAGE } from "./token";
import { IMediaStorageConfig } from "./media-storage.constant";
import { MediaStorageFeatures } from "./media-storage-feature";
import { MediaStorageService } from "./media-storage.service";

export function provideMediaStorage(configs:IMediaStorageConfig,...mediaStorageFeatures:MediaStorageFeatures[]):EnvironmentProviders{
  const providers:Provider[]=[
    MediaStorageService,
    {
      provide :REDOC_MEDIA_STORAGE,
      useValue:configs
    },
    mediaStorageFeatures.map(feature => feature.ɵproviders)
  ]
  return makeEnvironmentProviders(providers)
}
