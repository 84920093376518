import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AUTH_PROVIDER } from '../auth.token';
import { isPlatformServer } from '@angular/common';
import { AUTH_NAVIGATION_HANDLER } from '../auth-navigation-handler';

@Injectable()
export class NoAuthGuard {
  _authService = inject(AUTH_PROVIDER);
  platformId = inject(PLATFORM_ID);
  isServer = isPlatformServer(this.platformId);
  _router = inject(AUTH_NAVIGATION_HANDLER);
  /**
   * Constructor
   */

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(): Observable<boolean> {
    // console.log('this._authService', this._authService);
    // Check the authentication status
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        console.log('noAuth -->', authenticated);
        // If the user is authenticated...
        if (authenticated) {
          // Prevent the access
          return of(false).pipe(
            tap(() => {
              // Redirect to the root
              this._router.navigate(['']);
            })
          );
        }

        // Allow the access
        return of(true);
      })
    );
  }
}
