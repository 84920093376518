import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { REDOC_LAYOUT_CONFIG } from "./layout.token";
import { merge } from "lodash-es";
import { LayoutConfig } from "./layout.types";

@Injectable()
export class LayoutService
{
  private _config: BehaviorSubject<LayoutConfig>;

  /**
   * Constructor
   */
  constructor(@Inject(REDOC_LAYOUT_CONFIG) config: LayoutConfig)
  {
      // Private
      this._config = new BehaviorSubject(config);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for config
   */
  set config(value: any)
  {
      // Merge the new config over to the current config
      const config = merge({}, this._config.getValue(), value);

      // Execute the observable
      this._config.next(config);
  }

  get config$(): Observable<any>
  {
      return this._config.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resets the config to the default
   */
  reset(): void
  {
      // Set the config
      this._config.next(this.config);
  }
}
