import { inject } from "@angular/core";
import { GetPublicLinkFn, GetUploadLinkFn, PublicLinkPayload, REDOC_MEDIA_STORAGE, UploadLinkPayload } from "@redocco/material-ui";
import { ApiService } from "@shared/core/api";

export function  uploadLinkFn():GetUploadLinkFn{
  const apiService = inject(ApiService);
  const config = inject(REDOC_MEDIA_STORAGE)
  return (data: UploadLinkPayload & Record<string, any>) =>{
    return apiService.get(config.uploadLink,data)
  }
} ;
export function  publicLinkFn():GetPublicLinkFn{
  const apiService = inject(ApiService);
  const config = inject(REDOC_MEDIA_STORAGE)
  return ( data: PublicLinkPayload)=>{
    return apiService.get(config.publicLink,data)
  }
}
