import {
  EnvironmentProviders,
  InjectionToken,
  Provider,
  Type,
  importProvidersFrom,
  inject,
} from '@angular/core';
import {
  AuthApiLoader,
  AuthApiLoaderBase,
  UserAdapter,
  UserAdapterBase,
} from './auth-api.loader';
import { LOGIN_URL } from './auth.token';
import { IUser } from './auth.model';
import {
  RedocAuthCookieStorage,
  RedocAuthLocalStorage,
  RedocAuthStorage,
} from './storages';
import { CookieModule, CookieService } from 'ngx-cookie';
import { CookieReaderService, ICookieReader } from './cookie.service';
export const AUTH_API_LOADER = new InjectionToken<AuthApiLoaderBase>(
  'AUTH_API_LOADER'
);
export const AUTH_SSO_ENABLE = new InjectionToken<boolean>('AUTH_SSO_ENABLE', {
  providedIn: 'root',
  factory: () => false,
});
export const AUTH_STORAGE = new InjectionToken<RedocAuthStorage>(
  'AUTH_STORAGE',
  {
    providedIn: 'root',
    factory: () => {
      return inject(RedocAuthLocalStorage);
    },
  }
);
export const AUTH_USER_LOADER = new InjectionToken<UserAdapterBase<IUser>>(
  'AUTH_USER_LOADER',
  {
    providedIn: 'root',
    factory: () => inject(UserAdapter),
  }
);
export const AUTH_COOKIE_READER = new InjectionToken<ICookieReader>(
  'AUTH_COOKIE_READER'
);
/**
 * Helper type to represent a Router feature.
 *
 * @publicApi
 */
export interface AuthFeature<FeatureKind extends AuthFeatureKind> {
  ɵkind: FeatureKind;
  ɵproviders: (Provider | EnvironmentProviders)[];
}
/**
 * The list of features as an enum to uniquely type each feature.
 */
export const enum AuthFeatureKind {
  EnabledAuthApiLoader,
  EnabledAuthLoginRouter,
  EnabledUserLoader,
  EnabledAuthStorage,
  EnabledSSO,
}
export type EnabledAuthApiLoaderFeature =
  AuthFeature<AuthFeatureKind.EnabledAuthApiLoader>;
export type EnabledUserLoaderFeature =
  AuthFeature<AuthFeatureKind.EnabledUserLoader>;
export type EnabledAuthLoginRouterFeature =
  AuthFeature<AuthFeatureKind.EnabledAuthLoginRouter>;
export type EnabledAuthStorageFeature =
  AuthFeature<AuthFeatureKind.EnabledAuthStorage>;
export type EnabledSSOFeature = AuthFeature<AuthFeatureKind.EnabledSSO>;
export type AuthFeatures =
  | EnabledAuthApiLoaderFeature
  | EnabledUserLoaderFeature
  | EnabledAuthLoginRouterFeature
  | EnabledAuthStorageFeature
  | EnabledSSOFeature;
export function authFeature<FeatureKind extends AuthFeatureKind>(
  kind: FeatureKind,
  providers: (Provider | EnvironmentProviders)[]
): AuthFeature<FeatureKind> {
  return { ɵkind: kind, ɵproviders: providers };
}

export function withAuthApiLoader(
  instance: Type<AuthApiLoaderBase>
): EnabledAuthApiLoaderFeature {
  const providers: Provider[] = [
    {
      provide: AUTH_API_LOADER,
      useClass: instance,
    },
  ];
  return authFeature(AuthFeatureKind.EnabledAuthApiLoader, providers);
}
export function withLoginRoute(
  loginRoute: string
): EnabledAuthLoginRouterFeature {
  const providers: Provider[] = [
    {
      provide: LOGIN_URL,
      useValue: loginRoute,
    },
  ];
  return authFeature(AuthFeatureKind.EnabledAuthLoginRouter, providers);
}
export function withUserLoader(
  instance: Type<UserAdapterBase<IUser>>
): EnabledUserLoaderFeature {
  const providers: Provider[] = [
    {
      provide: AUTH_USER_LOADER,
      useClass: instance,
    },
  ];
  return authFeature(AuthFeatureKind.EnabledUserLoader, providers);
}
export function withAuthStorage(
  instance: Type<RedocAuthStorage>
): EnabledAuthStorageFeature {
  const providers: Provider[] = [
    {
      provide: AUTH_STORAGE,
      useClass: instance,
    },
  ];
  console.log(instance.name);
  // const temp = new instance();
  if (instance.name === 'RedocAuthCookieStorage') {
    providers.push({
      provide: AUTH_COOKIE_READER,
      useClass: CookieReaderService,
    });
  }
  return authFeature(AuthFeatureKind.EnabledAuthStorage, providers);
}
