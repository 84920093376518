export enum TransformationType {
  PLAIN_TO_CLASS,
  CLASS_TO_PLAIN,
  CLASS_TO_CLASS,
}
export interface TransformFnParams {
  value: any;
  // key: string;
  obj: any;
  transformType: TransformationType;
  // options: ClassTransformOptions;
}
