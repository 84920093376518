import { createAction, props } from '@ngrx/store';
import { UserAccountModel } from '@shared/data-access/models';

export const initUser = createAction('[User Page] Init');

export const loadUserSuccess = createAction(
  '[User/API] Load User Success',
  props<{ user: UserAccountModel }>()
);

export const loadUserFailure = createAction(
  '[User/API] Load User Failure',
  props<{ error: any }>()
);
