import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
export class HttpErrorWrapper {
  statusCode!: number;
  errorCode?: number;
  message!: string;
  constructor(options: { statusCode: number; errorCode?: number; message: string }) {
    this.statusCode = options.statusCode;
    this.errorCode = options.errorCode;
    this.message = options.message;
  }
}
@Injectable()
export class HttpErrorWrapperInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(
          () => new HttpErrorWrapper({ statusCode: error.status, errorCode: error.error?.errorCode, message: error.error?.message ?? error.message })
        );
      })
    );
  }
}
export function withHttpErrorWrapperInterceptor():HttpInterceptorFn{
  return (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    return next(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(
          () => new HttpErrorWrapper({ statusCode: error.status, errorCode: error.error?.errorCode, message: error.error?.message ?? error.message })
        );
      })
    );
  }
}
