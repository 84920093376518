<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">
  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <div
      class="flex flex-col lg:flex-row items-center lg:items-start lg:justify-center xl:justify-start flex-auto min-w-0"
    >
      <div
        class="xl:flex xl:items-center xl:justify-center w-full md:w-auto h-full xl:flex-auto p-8 pb-4 xl:pt-22 xl:px-56 md:pt-12 md:px-12"
      >
        <div
          class="flex flex-col w-full h-full max-w-120 md:w-120 mx-auto md:mx-0"
        >
          <!-- Logo -->
          <div class="flex flex-row justify-center w-full">
            <redoc-logo [dark]="true"></redoc-logo>
          </div>
          <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
           Otherwise, layout changes won't be registered and the view won't be updated! -->
          <router-outlet *ngIf="true"></router-outlet>
          <div class="flex  flex-row justify-center items-end pt-4">
            <redoc-copyright class="text-s3"></redoc-copyright>
          </div>
        </div>
      </div>
      <div class=" hidden xl:flex flex-auto xl:w-160 max-w-160 overflow-hidden h-full p-6  ">
        <div
        class="relative  flex flex-auto items-center justify-center xl:w-160 max-w-160 h-full  overflow-hidden bg-center bg-no-repeat bg-cover bg-splash-login dark:border-l rounded-3xl"
      >
        <!-- Background - @formatter:off -->
        <!-- Rings -->

        <!-- Dots -->

        <svg
          class="absolute top-16 -right-16"
          width="581"
          height="1067"
          viewBox="0 0 581 1067"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16"
            cy="746"
            r="16"
            fill="url(#paint0_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="16"
            cy="746"
            r="15.5"
            stroke="url(#paint1_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="229"
            cy="435"
            r="24"
            fill="url(#paint2_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="229"
            cy="435"
            r="23.5"
            stroke="url(#paint3_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="424"
            cy="363"
            r="10"
            fill="url(#paint4_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="424"
            cy="363"
            r="9.5"
            stroke="url(#paint5_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="160"
            cy="585"
            r="8"
            fill="url(#paint6_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="160"
            cy="585"
            r="7.5"
            stroke="url(#paint7_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="535"
            cy="411"
            r="4"
            fill="url(#paint8_radial_2598_3326)"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="230"
            cy="811"
            r="4"
            fill="url(#paint9_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="230"
            cy="811"
            r="3.5"
            stroke="url(#paint10_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="495"
            cy="66"
            r="5"
            fill="url(#paint11_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="495"
            cy="66"
            r="4.5"
            stroke="url(#paint12_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="464"
            cy="553"
            r="17"
            fill="url(#paint13_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="464"
            cy="553"
            r="16.5"
            stroke="url(#paint14_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="394"
            cy="17"
            r="17"
            fill="url(#paint15_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="394"
            cy="17"
            r="16.5"
            stroke="url(#paint16_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="384.5"
            cy="501.5"
            r="7.5"
            fill="url(#paint17_radial_2598_3326)"
            fill-opacity="0.3"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="384.5"
            cy="501.5"
            r="7"
            stroke="url(#paint18_linear_2598_3326)"
            stroke-opacity="0.4"
            style="mix-blend-mode: overlay"
          />
          <circle
            cx="439"
            cy="1060"
            r="163"
            fill="url(#paint19_radial_2598_3326)"
          />
          <defs>
            <radialGradient
              id="paint0_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(11.2941 760.588) rotate(-59.2373) scale(23.0011)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint1_linear_2598_3326"
              x1="16"
              y1="730"
              x2="16"
              y2="762"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint2_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(221.941 456.882) rotate(-59.2373) scale(34.5017)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint3_linear_2598_3326"
              x1="229"
              y1="411"
              x2="229"
              y2="459"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint4_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(428.583 359.458) rotate(134.421) scale(14.5848)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint5_linear_2598_3326"
              x1="424"
              y1="353"
              x2="424"
              y2="373"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint6_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(157.647 592.294) rotate(-59.2373) scale(11.5006)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint7_linear_2598_3326"
              x1="160"
              y1="577"
              x2="160"
              y2="593"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint8_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(533.824 414.647) rotate(-72.8015) scale(9.0518)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <radialGradient
              id="paint9_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(231.833 809.583) rotate(134.421) scale(5.83393)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint10_linear_2598_3326"
              x1="230"
              y1="807"
              x2="230"
              y2="815"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint11_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(493.529 70.5588) rotate(-59.2373) scale(7.18785)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint12_linear_2598_3326"
              x1="495"
              y1="61"
              x2="495"
              y2="71"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint13_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(459 568.5) rotate(-59.2373) scale(24.4387)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint14_linear_2598_3326"
              x1="464"
              y1="536"
              x2="464"
              y2="570"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint15_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(389 32.5) rotate(-59.2373) scale(24.4387)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint16_linear_2598_3326"
              x1="394"
              y1="0"
              x2="394"
              y2="34"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint17_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(382.294 508.338) rotate(-59.2373) scale(10.7818)"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </radialGradient>
            <linearGradient
              id="paint18_linear_2598_3326"
              x1="384.5"
              y1="494"
              x2="384.5"
              y2="509"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <radialGradient
              id="paint19_radial_2598_3326"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(439 1068.7) rotate(-90) scale(39.2039)"
            >
              <stop stop-color="#FFC700" />
              <stop offset="1" stop-color="#FFC700" stop-opacity="0" />
            </radialGradient>
          </defs>
        </svg>

        <div class="absolute bottom-0">
          <img src="assets/images/background/building.png" />
        </div>
        <!-- @formatter:on -->
        <!-- Content -->
        <div class="z-10 absolute top-16 left-16">
          <redoc-logo></redoc-logo>
          <div class="mt-20 relative w-full max-w-3xl">
            <div class="text-h2 font-bold text-white">
              <div>Digitizing Real Estate</div>
              <div>Contracts, Agreements,</div>
              <div>and Transactions.</div>
            </div>
          </div>
        </div>
      </div>
      </div>
    
    </div>
  </div>
</div>
