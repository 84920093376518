import { NavigationItem } from '@shared/components/layout';

export const defaultNavigation: NavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    // tooltip: 'Dashboard',
    type: 'basic',
    icon: 'dashboard',
    link: 'dashboard',
    meta: {
      permission: {
        disabled: true,
      },
    },
  },
  {
    id: 'business_partners',
    title: 'Business Partners',
    type: 'collapsable',
    icon: 'business_partners',
    isSvgIcon: true,
    children: [
      {
        id: 'client',
        title: 'Client',
        // tooltip: 'Dashboard',
        type: 'basic',
        link: 'client',
        meta: {
          permission: {
            disabled: true,
          },
        },
      },{
        id: 'receipt',
        title: 'Receipt',
        // tooltip: 'Dashboard',
        type: 'basic',
        link: 'receipt',
        meta: {
          permission: {
            disabled: true,
          },
        },
      },
    ],
  },

  {
    id: 'settings',
    title: 'Settings',
    type: 'collapsable',
    icon: 'settings',
    children: [
      {
        id: 'settings.profile',
        title: 'Profile',
        // tooltip: 'Dashboard',
        type: 'basic',
        link: 'settings/profile',
        meta: {
          permission: {
            disabled: true,
          },
        },
      },
    ],
  },
];

export const compactNavigation: NavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    tooltip: 'Dashboard',
    type: 'basic',
    icon: 'dashboard',
    link: 'dashboard',
    // children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'business_partners',
    title: 'Business Partners',
    tooltip: 'Business Partners',
    type: 'aside',
    icon: 'business_partners',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'settings',
    title: 'Settings',
    tooltip: 'Settings',
    type: 'basic',
    icon: 'settings',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];

export const defaultSettingNavigation: NavigationItem[] = [
  {
    id: 'profile',
    title: 'Profile',
    // tooltip: 'Dashboard',
    type: 'basic',
    icon: 'dashboard',
    link: 'settings/profile',
    meta: {
      permission: {
        disabled: true,
      },
    },
  },
];

export const compactSettingNavigation: NavigationItem[] = [
  {
    id: 'profile',
    title: 'Profile',
    tooltip: 'Profile',
    type: 'basic',
    icon: 'profile',
    link: 'settings/profile',
    // children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
