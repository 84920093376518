/* eslint-disable @typescript-eslint/no-explicit-any */
export const PROP_METADATA = '__redoc__prop__metadata__';
function makeMetadataCtor(props?: (...args: any[]) => any): any {
  return function ctor(this: any, ...args: any[]) {
    if (props) {
      const values = props(...args);
      for (const propName in values) {
        this[propName] = values[propName];
      }
    }
  };
}

export function makePropDecorator(
  name: string,
  props?: (...args: any[]) => any,
  parentClass?: any,
  additionalProcessing?: (target: any, name: string, ...args: any[]) => void
): any {
  const metaCtor = makeMetadataCtor(props);
  function PropDecoratorFactory(
    this: unknown | typeof PropDecoratorFactory,
    ...args: any[]
  ): any {
    if (this instanceof PropDecoratorFactory) {
      metaCtor.apply(this, args);
      return this;
    }
    const decoratorInstance = new (<any>PropDecoratorFactory)(...args);
    function PropDecorator(target: any, name: string) {
      // target is undefined with standard decorators. This case is not supported and will throw
      // if this decorator is used in JIT mode with standard decorators.
      if (target === undefined) {
        throw new Error(
          'Standard Angular field decorators are not supported in JIT mode.'
        );
      }

      const constructor = target.constructor;
      // Use of Object.defineProperty is important because it creates a non-enumerable property
      // which prevents the property from being copied during subclassing.
      const meta =
        PROP_METADATA in constructor
          ? (constructor as any)[PROP_METADATA]
          : Object.defineProperty(constructor, PROP_METADATA, { value: {} })[
              PROP_METADATA
            ];
      meta[name] = (name in meta && meta[name]) || [];
      meta[name].unshift(decoratorInstance);

      if (additionalProcessing) additionalProcessing(target, name, ...args);
    }

    return PropDecorator;
  }
  if (parentClass) {
    PropDecoratorFactory.prototype = Object.create(parentClass.prototype);
  }
  PropDecoratorFactory.prototype.ngMetadataName = name;
  (<any>PropDecoratorFactory).annotationCls = PropDecoratorFactory;
  return PropDecoratorFactory;
}
