import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageElementDirective } from './image-loader.directive';
import { ImageLoaderComponent } from './image-loader.component';



@NgModule({
  declarations: [
    ImageElementDirective,
    ImageLoaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ImageLoaderComponent,
    ImageElementDirective
  ]
})
export class ImageLoaderModule { }
