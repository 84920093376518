import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHandlerFn,
  HttpInterceptor,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  filter,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthInterceptor } from './auth.interceptor';
import { AuthUtils } from './auth.utils';

@Injectable()
export class AuthWithRefreshTokenInterceptor
  extends AuthInterceptor
  implements HttpInterceptor
{
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  /**
   * Constructor
   */

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  override intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('hello AuthWithRefreshTokenInterceptor');
    if (req.headers.get('source') !== 'ApiService') {
      return next.handle(req);
    }
    // Clone the request object
    let clonedReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
      this._authService.accessToken &&
      (!AuthUtils.isTokenExpired(this._authService.accessToken) ||
        req.url.indexOf('access-token') !== -1)
    ) {
      clonedReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this._authService.accessToken
        ),
      });
    }
    // Response
    return next.handle(clonedReq).pipe(
      catchError((error) => {
        console.log('error ->', error);
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (this.router.url.indexOf(this.loginUrl) === -1) {
            if (this._forcedLogoutFn && this._forcedLogoutFn(error)) {
              this.forceLogout();
            } else if (this.refreshTokenInProgress) {
              console.log('refreshTokenInProgress');
              return this.refreshTokenSubject.pipe(
                filter((val) => val !== null),
                take(1),
                switchMap(() => {
                  const newReq = req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      'Bearer ' + this._authService.accessToken
                    ),
                  });
                  console.log('refreshTokenInProgress next');
                  return next.handle(newReq);
                })
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);
              console.log('start to call api refreshToken');
              return this._authService.signInUsingRefreshToken().pipe(
                switchMap((isAuth) => {
                  console.log('isAuth --> ', isAuth);
                  if (isAuth) {
                    this.refreshTokenSubject.next(true);
                    this.refreshTokenInProgress = false;
                    const newReq = req.clone({
                      headers: req.headers.set(
                        'Authorization',
                        'Bearer ' + this._authService.accessToken
                      ),
                    });
                    return next.handle(newReq);
                  } else {
                    // expire token
                    return throwError(() => error);
                  }
                }),
                catchError((err) => {
                  console.log('catchError --> forced logout');
                  this.refreshTokenInProgress = false;
                  // Sign out
                  this.forceLogout();
                  return throwError(() => err);
                })
              );
            }
          }
        }

        return throwError(() => error);
      })
    );
  }
}
@Injectable()
export class AuthWithRefreshTokenInterceptorHandler
  extends AuthInterceptor
  implements HttpInterceptor
{
  refreshTokenInProgress = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
}

export function withAuthWithRefreshTokenInterceptor(): HttpInterceptorFn {
  return (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    // console.log('error ->', req);
    const _authHandler = inject(AuthWithRefreshTokenInterceptorHandler);

    const router = inject(Router);
    if (req.headers.get('source') !== 'RedocApiService') {
      console.log('error source->', req.headers.get('source'));
      return next(req);
    }
    // Clone the request object
    let clonedReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
      _authHandler._authService.accessToken &&
      (!AuthUtils.isTokenExpired(_authHandler._authService.accessToken) ||
        req.url.indexOf('access-token') !== -1)
    ) {
      clonedReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + _authHandler._authService.accessToken
        ),
      });
    }
    // Response
    return next(clonedReq).pipe(
      catchError((error) => {
        console.log('error ->', error);
        // Catch "401 Unauthorized" responses
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (clonedReq.body && (clonedReq.body as any).refreshToken) {
            console.log('_forcedLogoutFn');
            _authHandler.forceLogout();
            return throwError(() => error);
          }
          if (router.url.indexOf(_authHandler.loginUrl) === -1) {
            if (
              _authHandler._forcedLogoutFn &&
              _authHandler._forcedLogoutFn(error)
            ) {
              console.log('_forcedLogoutFn');
              _authHandler.forceLogout();
              return throwError(() => error);
            } else if (_authHandler.refreshTokenInProgress) {
              console.log('refreshTokenInProgress');
              return _authHandler.refreshTokenSubject.pipe(
                filter((val) => val !== null),
                take(1),
                switchMap(() => {
                  const newReq = req.clone({
                    headers: req.headers.set(
                      'Authorization',
                      'Bearer ' + _authHandler._authService.accessToken
                    ),
                  });
                  console.log('refreshTokenInProgress next');
                  return next(newReq);
                })
              );
            } else {
              _authHandler.refreshTokenInProgress = true;
              _authHandler.refreshTokenSubject.next(null);
              console.log('start to call api refreshToken');
              return _authHandler._authService.signInUsingRefreshToken().pipe(
                switchMap((isAuth) => {
                  console.log('isAuth --> ', isAuth);
                  if (isAuth) {
                    _authHandler.refreshTokenSubject.next(true);
                    _authHandler.refreshTokenInProgress = false;
                    const newReq = req.clone({
                      headers: req.headers.set(
                        'Authorization',
                        'Bearer ' + _authHandler._authService.accessToken
                      ),
                    });
                    return next(newReq);
                  } else {
                    // expire token
                    return throwError(() => error);
                  }
                }),
                catchError((err) => {
                  console.log('catchError --> forced logout');
                  _authHandler.refreshTokenInProgress = false;
                  // Sign out
                  _authHandler.forceLogout();
                  return throwError(() => err);
                })
              );
            }
          }
        }

        return throwError(() => error);
      })
    );
  };
}
