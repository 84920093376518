import { EnvironmentProviders, Provider, makeEnvironmentProviders } from "@angular/core"
import { ImageLoaderFn, IMAGE_LOADER_FN } from "./image-loader.token"
import { of } from "rxjs"

export const imageLoaderDefaultFn:ImageLoaderFn = (urlPath:string)=> of(urlPath)
export function provideImageLoaderFn(fn:()=>ImageLoaderFn):EnvironmentProviders{
  const providers:Provider[]= [
    {
      provide:IMAGE_LOADER_FN,
      useFactory:fn
    }
  ]
  return makeEnvironmentProviders(providers)
}
