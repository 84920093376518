import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AUTH_PROVIDER, LOGIN_URL } from '../auth.token';
import { AUTH_NAVIGATION_HANDLER } from '../auth-navigation-handler';

@Injectable()
export class AuthGuard {
  platformId = inject(PLATFORM_ID);
  _authService = inject(AUTH_PROVIDER);
  _router = inject(AUTH_NAVIGATION_HANDLER);
  /**
   * Constructor
   */
  constructor(@Inject(LOGIN_URL) private loginUrl: string) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(redirectURL: string): Observable<boolean> {
    // Check the authentication status

    return this._authService.check().pipe(
      switchMap((authenticated) => {
        if (isPlatformServer(this.platformId)) {
          console.log(
            'authenticated isServer---> ',
            isPlatformServer(this.platformId),
            authenticated
          );
        } else {
          console.log('authenticated isCLient---> ', authenticated);
        }
        // If the user is not authenticated...
        if (!authenticated) {
          // Redirect to the sign-in page
          return of(false).pipe(
            tap(() => {
              console.log('loginUrl ---> ', this.loginUrl);
              console.log('redirectURL ---> ', redirectURL);
              this._router.navigate([this.loginUrl], {
                queryParams: { redirectURL },
              });
            })
          );

          // Prevent the access
          return of(false);
        }

        // Allow the access
        return of(true);
      })
    );
  }
}
