import { ClassProvider, Injectable, InjectionToken, Type, inject } from "@angular/core";
import { Observable, ReplaySubject, of } from "rxjs";
import { VerticalNavigationGroup } from "../../common/navigation/navigation.types";
import { HttpClient } from "@angular/common/http";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanLoadFn, RouterStateSnapshot } from "@angular/router";

export const VERTICAL_NAVIGATION_DATA_LOADER = new InjectionToken<NavigationDataLoaderBaseService>('VERTICAL_NAVIGATION_DATA_LOADER');

@Injectable()
export abstract class NavigationDataLoaderBaseService {
  protected _navigation: ReplaySubject<VerticalNavigationGroup> = new ReplaySubject<VerticalNavigationGroup>(1);
  /**
   * Constructor
   */
  constructor(protected _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<VerticalNavigationGroup> {
    return this._navigation.asObservable();
  }

  /**
   * Settet for navigation
   */
  set navigation(val:VerticalNavigationGroup){
    this._navigation.next(val)
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  abstract get(): Observable<VerticalNavigationGroup>;
}
@Injectable()
export class NavigationDataLoaderDefaultService extends NavigationDataLoaderBaseService{
  override get(): Observable<VerticalNavigationGroup> {
    console.log('NavigationDataLoaderDefaultService get')
    return of({})
  }
}

