import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthProviderBase } from './auth.service';
import { AUTH_PROVIDER, FORCED_LOGOUT_FN, LOGIN_URL } from './auth.token';
import { AuthUtils } from './auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */
  constructor(
    @Inject(AUTH_PROVIDER) public _authService: AuthProviderBase,
    @Inject(LOGIN_URL) public loginUrl: string,
    @Optional()
    @Inject(FORCED_LOGOUT_FN)
    public _forcedLogoutFn: (error: HttpErrorResponse) => boolean,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get('source') !== 'RedocApiService') {
      return next.handle(req);
    }
    // Clone the request object
    let newReq = req.clone();

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
      this._authService.accessToken &&
      (!AuthUtils.isTokenExpired(this._authService.accessToken) ||
        req.url.indexOf('access-token') !== -1)
    ) {
      newReq = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this._authService.accessToken
        ),
      });
    }
    // Response
    return next.handle(newReq).pipe(
      catchError((error) => {
        console.log('error ->', error);
        if (!error) {
          this.forceLogout();
        }
        // Catch "401 Unauthorized" responses
        else if (
          ((error instanceof HttpErrorResponse && error.status === 401) ||
            error.statusCode === 401) &&
          this.router.url.indexOf(this.loginUrl) === -1
        ) {
          this.forceLogout();
        } else if (error.status === 500 || error.statusCode === 500) {
          this.router.navigate(['error/500'], { skipLocationChange: true });
        } else if (this._forcedLogoutFn && this._forcedLogoutFn(error)) {
          this.forceLogout();
        }
        return throwError(() => error);
      })
    );
  }
  forceLogout(): void {
    // Sign out
    this._authService.signOut();

    // Reload the app
    location.reload();
  }
}
