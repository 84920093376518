import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AUTH_PROVIDER } from '../auth.token';
import { AuthUtils } from '../auth.utils';
export const TOKEN_AUTH_NAME = new InjectionToken<string>('TOKEN_AUTH_NAME', {
  providedIn: 'root',
  factory: () => 'token',
});

@Injectable()
export class TokenAuthGuard implements CanActivate, CanActivateChild {
  _authService = inject(AUTH_PROVIDER);
  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    @Inject(TOKEN_AUTH_NAME) private queryParamTokem: string
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(route, redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(childRoute, redirectUrl);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private _check(
    route: ActivatedRouteSnapshot,
    redirectURL: string
  ): Observable<boolean> {
    const token = route.queryParamMap.get(this.queryParamTokem);
    return of(token).pipe(
      switchMap((token) => {
        if (!token) {
          return of(false);
        }
        if (AuthUtils.isTokenExpired(token)) {
          return of(false);
        }
        return of(true);
      }),
      tap((canAccess) => {
        if (!canAccess) {
          this._router.navigate(['error', '404'], { skipLocationChange: true });
        }
      })
    );
  }
}
