import { InjectionToken, Provider, Type } from "@angular/core";
import { GET_PUBLIC_LINK_FN, GET_UPLOAD_LINK_FN, GetPublicLinkFn, GetUploadLinkFn } from "./token";


/**
 * Helper type to represent a MediaStorage feature.
 *
 * @publicApi
 */
export interface MediaStorageFeature<FeatureKind extends MediaStorageFeatureKind> {
  ɵkind: FeatureKind;
  ɵproviders: Provider[];
}
/**
 * The list of features as an enum to uniquely type each feature.
 */
export const enum MediaStorageFeatureKind {
  EnabledPublicLinkFn,
  EnabledUploadLinkFn,
}
export type EnabledPublicLinkFnFeature = MediaStorageFeature<MediaStorageFeatureKind.EnabledPublicLinkFn>;
export type EnabledUploadLinkFnFeature = MediaStorageFeature<MediaStorageFeatureKind.EnabledUploadLinkFn>;


export type MediaStorageFeatures = EnabledPublicLinkFnFeature | EnabledUploadLinkFnFeature ;

export function mediaStorageFeature<FeatureKind extends MediaStorageFeatureKind>(
  kind: FeatureKind, providers: Provider[]): MediaStorageFeature<FeatureKind> {
return {ɵkind: kind, ɵproviders: providers};
}

export function withPublicLinkFnFactory(fn:()=> GetPublicLinkFn):EnabledPublicLinkFnFeature{
  const providers:Provider[] = [
    {
      provide:GET_PUBLIC_LINK_FN,
      useFactory:fn
    }
  ]
  return mediaStorageFeature(MediaStorageFeatureKind.EnabledPublicLinkFn,providers)
}

export function withUploadLinkFnFactory(fn:()=>GetUploadLinkFn):EnabledUploadLinkFnFeature{
  const providers:Provider[] = [
    {
      provide:GET_UPLOAD_LINK_FN,
      useFactory:fn
    }
  ]
  return mediaStorageFeature(MediaStorageFeatureKind.EnabledUploadLinkFn,providers)
}
