// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Theme = 'default' | string;

export type Layout = 'empty' | 'wellcome' | 'classic';

/**
 * LayoutConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface LayoutConfig {
  layout: Layout;
  scheme: Scheme;
  theme: Theme;
}

/**
 * Default configuration for the entire application. This object is used by
 * LayoutConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * LayoutConfigService and its methods.
 */
export const layoutConfigDefault: LayoutConfig = {
  layout: 'classic',
  scheme: 'light',
  theme: 'default',
};
