/* eslint-disable @typescript-eslint/no-explicit-any */
import { Executors } from '../executors';
import { TransformationType } from '../interfaces';
import { ClassConstructor } from '../interfaces/class-constructor.interface';
import { ClassTransformOptions } from '../interfaces/class-transform-options.interface';
const executors = new Executors();
export function plainToClass<T extends Record<string, any>, V>(
  cls: ClassConstructor<T>,
  plain: V,
  options?: ClassTransformOptions
): T {
  return executors.transform(
    TransformationType.PLAIN_TO_CLASS,
    undefined,
    plain,
    cls,
    options
  );
  // const newValue = new cls();
  // return newValue;
}
