/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClassConstructor } from './interfaces/class-constructor.interface';
import { makePropDecorator } from './util';
// import { Transform } from './transform.decorator';
// export type FieldOptions = Pick<Transform, 'alias' | 'optional'> & {
//   type: ClassConstructor<any>;
// };
// // export function Field(option: ClassConstructor<any>):Transform
// // export function Field(option: Transform):Transform
// export function Field(option: Partial<FieldOptions>) {
//   return Transform(option);
// }
export interface Field {
  /**
   * The name of the DOM property to which the input property is bound.
   */
  alias?: string;

  /**
   * Whether the input is required for the directive to function.
   */
  optional?: boolean;
  /**
   * Whether the input is required for the directive to function.
   */
  read?: ClassConstructor<any>;
}
export interface FieldDecorator {
  (arg?: string | Field): any;
  new (arg?: string | Field): any;
}
export const Field: FieldDecorator = makePropDecorator(
  'Field',
  (arg?: string | Field) => {
    if (!arg) {
      return {};
    }
    return typeof arg === 'string' ? { alias: arg } : arg;
  }
);
