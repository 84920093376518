import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'redoc-logo',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class LogoComponent {
  @Input({ alias: 'dark', required: false }) get isDark() {
    return this._isDark;
  }
  set isDark(val) {
    this._isDark = coerceBooleanProperty(val);
  }
  private _isDark = false;
}
