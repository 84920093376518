import { Injectable, inject } from '@angular/core';
import {
  RedocAuthStorage,
  RedocAuthStorageOptions,
  RedocAuthStorageType,
} from './storage.interface';

@Injectable()
export class RedocAuthLocalStorage implements RedocAuthStorage {
  type: RedocAuthStorageType = 'localStroge';
  window = inject(Window, { optional: true });
  setItem(key: string, value: string, options?: RedocAuthStorageOptions): void {
    window?.localStorage?.setItem(key, value);
  }
  getItem(key: string): string | null {
    return window?.localStorage?.getItem(key);
  }
  removeItem(key: string): void {
    window?.localStorage?.removeItem(key);
  }
  clear(): void {
    window?.localStorage?.clear();
  }
}
